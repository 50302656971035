.category-grid {
  background: linear-gradient(to bottom, transparent 50%, $color-pink-2 50%);
  height: 160px;
  padding-top: 20px;
  @include breakpoint($medium-up) {
    padding-top: 70px;
    &.pc-flex-grid {
      display: flex;
      height: 275px;
    }
  }
  @include breakpoint($landscape-up) {
    &.pc-flex-grid {
      flex: 1;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
  .slick-slide {
    margin: 0 10px;
  }
  &__item {
    padding-bottom: 7px;
    @include breakpoint($medium-up) {
      padding-bottom: 65px;
      margin: 0 40px;
    }
    a {
      display: block;
      text-decoration: none;
    }
    img {
      width: auto;
      margin: auto;
      @include breakpoint($small-down) {
        max-height: 100px;
      }
      @include breakpoint($medium-up) {
        max-width: 200px;
      }
      @include breakpoint($landscape-up) {
        width: 100%;
      }
    }
  }
  &__image,
  &__link {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
  }
  &__image {
    height: 110px;
    @include breakpoint($medium-up) {
      height: 161px;
    }
  }
  &__name {
    color: $color-dark-gray;
    display: block;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 1px;
    margin-top: 7px;
    @include breakpoint($medium-up) {
      font-size: 14px;
      margin-top: 20px;
    }
  }
}
