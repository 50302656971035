@mixin mpp-inline-content-tout() {
  max-width: 600px;
  margin: 0 auto;
  .product-grid & {
    margin: 0px;
    max-width: none; // max-width is only for viewing content block on its own
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
  }
  .content-block__picture {
    height: 100%;
  }
  @include contentBlockImageHeight;
  .content-block__line {
    &--eyebrow {
      .style--bare {
        @include body-small;
      }
    }
    &--header {
      margin-bottom: 5px;
      .style--bare {
        text-transform: uppercase;
        @include header-medium;
        font-size: mobilePx(40px);
        @include breakpoint($large-up) {
          font-size: 40px;
          line-height: 42px;
        }
      }
    }
    &--content {
      margin: 0px;
      .style--bare {
        font-size: mobilePx(30px);
        line-height: mobilePx(40px);
        @include font--proxima-nova;
        @include breakpoint($large-up) {
          font-size: 36px;
          line-height: 40px;
        }
      }
    }
    &--content--2 {
      margin: 0px;
      .style--bare {
        @include body-medium;
        line-height: 1;
        font-size: 12px;
        @include breakpoint($large-up) {
          font-size: 18px;
        }
      }
    }
  }
  .content-block__content {
    padding: 0 15px;
    &-text {
      &.padding-vertical-default {
        padding-top: 30px;
        padding-bottom: 30px;
      }
      &.padding-horizontal-default {
        padding-left: 7px;
        padding-right: 7px;
      }
      &--default-background {
        background: rgba(255, 255, 255, 0.9);
      }
      &--disable-background {
        background: none !important;
      }
      &.default-max-width {
        max-width: 90%;
      }
    }
    &-buttons {
      margin-top: 5px;
      @include breakpoint($large-up) {
        margin-top: 15px;
      }
      .link {
        @include link--inline-tout;
      }
    }
  }
}

.mpp-inline-tout.content-block--hero {
  @include mpp-inline-content-tout;
  .content-block__content {
    @include maxWidths;
  }
}

// adjustments for colspan = 2 inline touts
.product-grid__item[data-grid-colspan='2'] {
  .mpp-inline-tout.content-block--hero {
    .content-block__content {
      &-text {
        &.padding-vertical-default {
          padding-top: 25px;
          padding-bottom: 25px;
        }
        &.padding-horizontal-default {
          padding-left: 25px;
          padding-right: 25px;
        }
        &.default-max-width {
          max-width: 75%;
          @include breakpoint($large-up) {
            max-width: 80%;
          }
        }
      }
      &-buttons {
        .link {
          @include body-medium;
          font-weight: 700;
          text-decoration: underline;
        }
      }
    }
    .content-block__line {
      &--header {
        @include breakpoint($large-up) {
          margin-bottom: 0px;
        }
        .style--bare {
          font-size: mobilePx(45px);
          line-height: mobilePx(48px);
          @include breakpoint($large-up) {
            font-size: 45px;
            line-height: 48px;
          }
        }
      }
    }
  }
}

.mpp-inline-tout.content-block--hero.mpp-inline-tout--style-hangover_tout {
  .content-block {
    &__line--content {
      margin-bottom: 5px;
      .style--bare {
        @include title-large;
        line-height: 1.2;
        @include breakpoint($medium-up) {
          font-size: 28px;
        }
      }
    }
    &__line--content--2 {
      .style--bare {
        @include body-medium;
        font-size: 13px;
        line-height: 1.4;
        @include breakpoint($medium-up) {
          line-height: 1.2;
          font-size: 18px;
        }
      }
    }
  }
}
