.product-details {
  @if $cr24 {
    background-color: $color-pink-2;
  }
  position: relative;
  &:before {
    display: none;
    bottom: 20px;
    content: ' ';
    z-index: 10;
    @include breakpoint($large-up) {
      display: block;
      bottom: 0;
    }
  }
  &__menu {
    .slick-track {
      .product-details__menu-title:only-child {
        display: none;
      }
    }
  }
}

.product-details__menu {
  font-family: $font--proxima-nova;
  max-width: 1100px;
  margin: 0 auto;
  text-transform: uppercase;
  &:not(.slick-initialized) {
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    // initially default first child as slick-current
    .product-details__menu-title:first-child {
      font-weight: bold;
      background: $color-pink-2;
    }
  }
  &-title {
    @include eyebrow-large;
    cursor: pointer;
    flex: 1;
    padding: 16px 0;
    text-align: center;
    &.slick-current {
      background: $color-pink-2;
    }
  }
}

.product-details__sections {
  @include body-small;
  &-inner {
    background: $color-pink-2;
    height: 100%;
    &:not(.slick-initialized) {
      overflow: hidden;
      display: flex;
      justify-content: flex-start;
      .product-details__section {
        flex-shrink: 0;
        overflow: hidden;
        display: inline-block;
        width: 100%;
      }
    }
  }
}

.product-details__section {
  height: 100%;
  &--ingredients {
    .product-details__section__inner,
    .product-details__section-inner {
      .product-detail-item__text-inner {
        @if $cr24 == true {
          height: auto;
        } @else {
          height: 350px;
        }
        overflow-y: auto;
        width: 100%;
        .product-ingredients {
          &__listing {
            @include input-styles;
            width: 100%;
            height: auto;
            border: 0;
            resize: none;
            background: transparent;
            padding: 0;
            line-height: 1.4;
          }
        }
      }
    }
  }
}

.product-detail-item {
  position: relative;
  background-size: cover;
  padding: 50px 0;
  height: 100%;
  &__text {
    @if $cr24 {
      max-height: auto;
    } @else {
      max-height: 200px;
    }
    overflow: hidden;
    @include breakpoint($large-up) {
      max-height: none;
      overflow: auto;
      margin: 0 auto;
      // mobile needs ability for inner content to have height.
      // so need flex only on desktop for centering
      display: flex;
      @if $cr24 {
        max-width: $product-full-accordion-max-width;
        justify-content: start;
      } @else {
        max-width: 925px;
        justify-content: center;
      }
    }
  }
  &--text-expanded &__text {
    max-height: 9999px;
  }
  &__text-inner {
    display: flex;
    flex-direction: column;
    @include breakpoint($large-up) {
      @if $cr24 {
        min-height: auto;
      } @else {
        min-height: 350px;
      }
      flex-direction: row;
    }
  }
  &__text-typography {
    width: 100%;
    padding: 0 25px;
  }
  // has two col
  &--2-col &__text-typography {
    @include breakpoint($large-up) {
      @if $cr24 {
        max-width: auto;
      } @else {
        max-width: 440px;
        padding: 0;
      }
    }
  }
  // implicitly this means --2-col is flagged
  &__text-column_2 {
    @include breakpoint($large-up) {
      margin-left: 50px;
    }
  }
  u {
    position: relative;
    text-decoration: none;
    display: inline-block;
    &:before {
      position: absolute;
      bottom: -5px;
      left: 50%;
      @include transform(translate(-50%, -50%));
      content: '';
      background: $color-black;
      height: 1px;
      width: 100%;
    }
  }
  .arrows {
    opacity: 1;
    z-index: 2;
    position: absolute;
    bottom: 25px;
    left: 50%;
    @include transform(translate(-50%, 50%));
    &--hidden {
      display: none;
      opacity: 0;
    }
  }
  .up-arrow,
  .down-arrow {
    display: none;
    font-size: 23px;
  }
  .up-arrow {
    display: flex;
    background: $color-border-gold-brown;
    .icon--plus,
    .icon--arrow--down {
      height: 24px;
      padding: 4px;
      width: 24px;
    }
    .icon--plus {
      fill: $color-pink-2;
    }
    .icon--arrow--down {
      display: none;
    }
  }
  &--text-expanded .up-arrow {
    background: transparent;
    .icon--plus {
      display: none;
    }
    .icon--arrow--down {
      @include transform(rotate(-180deg));
      fill: $color-border-gold-brown;
      display: block;
    }
  }
}

.product-details__section {
  .custom-text {
    @include editorial-lists;
  }
}

// Font size changes for detailt ab
.product-details__section--details {
  .custom-text {
    li {
      margin-top: 10px;
    }
  }
  ul {
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 15px;
  }
  .text--border-underline {
    margin-top: 15px; // assume section above it has 15px bottom margin
    margin-bottom: 15px;
  }
  .text--border-underline:first-child {
    margin-top: 0;
  }
}

.product-details__section--tips {
  text-align: center;
  &.product-details__section--how-to {
    min-height: 420px;
    .product-details__section {
      &-inner {
        .product-detail-item {
          padding-bottom: 0;
          min-height: 0;
        }
      }
      &-inner ~ .product-details__section-inner {
        .product-detail-item {
          padding-top: 0;
          &__text-typography {
            max-width: 100%;
          }
        }
      }
    }
  }
  .product-detail-item__text-typography {
    max-width: 450px;
    margin: 0 auto;
  }
  .product-detail-item__text-inner {
    @include breakpoint($large-up) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .product-detail-subitem {
    margin-bottom: 20px;
    width: 100%;
    @include breakpoint($large-up) {
      width: 50%;
      max-width: 400px;
    }
    &:nth-child(2n + 2) {
      @include breakpoint($large-up) {
        margin-left: 100px;
      }
    }
    &__text {
      &-title {
        @include tips-header;
      }
      &-body {
        @include body-small;
        line-height: mobilePx(38px);
        margin: 0 auto;
        max-width: mobilePx(538px);
        @include breakpoint($large-up) {
          max-width: 390px;
          font-size: 22px;
          line-height: 30px;
        }
      }
    }
  }
}

// *
// * overrides for product details when placed within product full vertical accordion
// *
.product-full__accordion__container {
  .product-detail-item {
    padding: 20px 0;
    @include breakpoint($landscape-up) {
      padding: 20px 25px;
    }
  }
}
