.site-footer-wrapper {
  .sticky-footer-chat {
    position: fixed;
    right: 20px;
    bottom: 0;
    z-index: 1000;
    width: 0;
    cursor: pointer;
    &__title {
      &:hover {
        margin: 0!important;
        padding: 0!important;
        color: $color-black;
      }
    }
    .LPMcontainer {
      outline: none;

      // For old chat icon, can be removed after change is in effect
      span {
        background: $color-black;
        box-shadow: -25px 0 0 0px $color-black;
        padding: 6px 8px;
        z-index: -1;
        position: relative;
      }
    }
  }
}

body.using-spp-layout {
  .site-footer-wrapper {
    .sticky-footer-chat {
      padding: 0;

      @include breakpoint($med-small-down) {
        bottom: 120px;
      }
      .LPMcontainer {
        transition: all 0.5s;
      }
    }
  }
}
