.filterable-grid-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0;
  @include breakpoint($large-up) {
    flex-direction: row;
    margin-bottom: 25px;
  }
  &.js-loaded &__mobile {
    button {
      opacity: 0.6;
      &.active {
        opacity: 1;
      }
    }
  }
  &.js-loaded &__category:not(.active) {
    display: none;
    @include breakpoint($large-up) {
      display: flex;
    }
  }
  &__mobile {
    display: flex;
    justify-content: center;
    background: $color-lipfinder-pink;
    padding: 10px 0;
    @include breakpoint($large-up) {
      display: none;
    }
    button {
      @include font--gotcha-regular;
      background: none;
      color: $color-lipfinder-menu-pink;
      font-size: 38px;
      padding: 0 35px;
    }
  }
  &__category-wrapper {
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
  }
  &__category {
    display: flex;
    width: 100%;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    @include breakpoint($large-up) {
      flex-shrink: 1;
      flex-direction: row;
      width: auto;
    }
  }
  &__header {
    color: $color-lipfinder-menu-pink;
    background: $color-lipfinder-pink;
    font-size: 23px;
    line-height: 2;
    padding: 5px 15px;
    display: none;
    @include breakpoint($large-up) {
      display: block;
    }
    .text-label {
      @include font--proxima-nova-bold;
      display: none;
      @include breakpoint($large-up) {
        display: inline-block;
      }
    }
    .category-name {
      @include font--gotcha-regular;
      font-size: 44px;
      line-height: 0.86;
      vertical-align: sub;
      white-space: nowrap;
    }
  }
  &__tags {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    @include breakpoint($large-up) {
      overflow: visible;
    }
  }
  &__tag {
    @include display-grid;
    @include grid-columns(0, 1fr);
    position: relative;
    justify-content: center;
    align-items: center;
    .icon {
      @include grid-element(1, 1, 1, 1);
      display: none;
      fill: $color-lipfinder-pastel-pink;
      height: 30px;
      margin: 0 auto;
      width: 30px;
      @include breakpoint($large-up) {
        height: 35px;
        width: 35px;
      }
    }
    &.active .icon{
      display: block;
    }
    button[data-filter-tag] {
      @include font--proxima-nova-bold;
      @include grid-element(1, 1, 1, 1);
      background: none;
      border: none;
      color: $color-lipfinder-gold;
      font-size: 15px;
      padding: 5px 15px;
      text-transform: uppercase;
      position: relative;
      min-height: 55px;
      @include breakpoint($large-up) {
        font-size: 18px;
        min-height: 50px;
      }
      &:hover {
        cursor: pointer;
      }
      &.active {
        @include font--proxima-nova-bold;
        color: $color-lipfinder-pink;
      }
    }
  }
}
