/*
  Colorbox Core Style:
  The following CSS is consistent between example themes and should not be altered.
*/
// @setup new site - this is cleaned up css from the plugin
// alter as needed per brand
// default is from example #3 dark bg, light overlay
$color-transparent--black: rgba(0, 0, 0, 0.8);

#colorbox,
#cboxOverlay,
#cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
}

#cboxWrapper {
  max-width: none;
  @include breakpoint($medium-up) {
    background: $color-white;
    .colorbox--video & {
      background: none;
    }
    .colorbox--quiz--loading & {
      background: transparent;
    }
    .colorbox--waitlist & {
      background: transparent;
    }
  }
  .bare & {
    padding: 0;
    background: transparent;
  }
}

.colorbox--video {
  video {
    height: auto;
    width: 100%;
  }
  .embed-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

#cboxOverlay {
  background: $color-transparent--black;
  position: fixed;
  width: 100%;
  height: 100%;
}

#cboxMiddleLeft,
#cboxBottomLeft {
  clear: left;
}

#cboxContent {
  position: relative;
}

#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background: $color-white;
  padding: 30px 20px 20px 20px;
  @include breakpoint($large-up) {
    padding: 40px 35px 30px 35px;
  }
  .bare & {
    padding: 0;
    background: transparent;
  }
  .qs-overlay-cbox & {
    padding: 0;
  }
  .colorbox--video & {
    padding: 0;
    background: none;
    color: $color-white;
  }
  .colorbox--quiz--loading & {
    background: transparent;
    padding: 0;
  }
  .colorbox--waitlist & {
    background: transparent;
    padding: 0;
  }
}

#cboxTitle {
  margin: 0;
  position: absolute;
  top: -20px;
  left: 0;
  color: $color-gray;
}

#cboxLoadingOverlay,
#cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow {
  cursor: pointer;
}

.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
  -ms-interpolation-mode: bicubic;
}

.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
}

#colorbox,
#cboxContent,
#cboxLoadedContent {
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}
/*
  User Style:
  Change the following styles to modify the appearance of Colorbox.
*/

#colorbox {
  outline: 0;
}

#cboxError {
  padding: 50px;
  border: 1px solid $color-black;
}

#cboxCurrent {
  position: absolute;
  top: -20px;
  right: 0;
  color: $color-gray;
}
// #cboxLoadingGraphic {
//   //background: url('/media/images/global/ajax-loading--white.gif') no-repeat 50%;
// }
/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */

#cboxPrevious,
#cboxNext,
#cboxSlideshow,
#cboxClose {
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  width: auto;
  background: none;
}

// Hide for now. We don't ever use these and they mess up ADA.
#cboxPrevious,
#cboxNext,
#cboxSlideshow {
  display: none;
}
/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */

#cboxPrevious:active,
#cboxNext:active,
#cboxSlideshow:active,
#cboxClose:active {
  outline: 0;
}

#cboxSlideshow {
  position: absolute;
  top: -20px;
  right: 90px;
  color: $color-white;
}

#cboxPrevious {
  position: absolute;
  top: 50%;
  left: 5px;
  margin-top: -32px;
  //background: url(#{$base-theme-path}/img/vendor/colorbox/controls.png) no-repeat;
  width: 28px;
  height: 65px;
  text-indent: -9999px;
}

#cboxPrevious:hover {
  background-position: bottom left;
}

#cboxNext {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -32px;
  //background: url(#{$base-theme-path}/img/vendor/colorbox/controls.png) no-repeat top right;
  width: 28px;
  height: 65px;
  text-indent: -9999px;
}

#cboxNext:hover {
  background-position: bottom right;
}

#cboxClose {
  background: url(#{$base-theme-path}/svg-icons/src/close.svg) no-repeat 50%;
  background-size: 19px;
  color: $color-black;
  padding: 20px;
  position: absolute;
  right: 0;
  top: 0;
  line-height: normal;
  font-size: 0; // hides 'close' text generated from plugin script
  .colorbox--video & {
    position: fixed;
    color: $color-white;
    top: 15px;
    right: 15px;
    padding: 0;
    @include breakpoint($large-up) {
      top: 30px;
      right: 30px;
      padding: 0;
    }
    &:before {
      font-size: 30px;
      line-height: 30px;
      @include breakpoint($large-up) {
        font-size: 40px;
        line-height: 40px;
      }
    }
  }
}
