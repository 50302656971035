%button,
.button {
  @include button;
}

%link,
.link {
  @include link;
}

button {
  img {
    vertical-align: top;
    height: 100%;
  }
}

.optanon-toggle-display {
  cursor: pointer;
  text-decoration: underline;
}
