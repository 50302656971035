.product-grid {
  $padding-x: $product-grid-padding-x;
  // normal 1 col brief dimensions
  $brief-width: $product-grid-brief-width;
  $brief-height: $product-grid-brief-height;
  $height-ratio: $brief-height / $brief-width;
  width: 100%;
  $grid-item-selector: #{&}__item;
  .product-grid__content {
    @include pie-clearfix;
    max-width: $product-grid-content-max-width;
    margin: 0 auto;
  }

  // non carousel grid
  $grid-grid: '.product-grid__content:not(.slick-initialized)';
  &:not([data-carousel-active='1']) {
    #{$grid-grid} {
      // TODO: should the centering be done via class? Really this is just for
      // cross sells since other grids will likely be filled out since they are
      // done manually
      @include pie-clearfix;
      @include display-grid;
      @include mantle-grid-mixin($grid-item-selector, $padding-x, $height-ratio);
      justify-content: center;
      grid-auto-flow: row dense;
      overflow: hidden;
    }

    // Setup default col settings.
    @include mantle-grid-columns-2($grid-grid);
    @include breakpoint($large-up) {
      // default of 4 cols
      @include mantle-grid-columns-4($grid-grid);
    }

    // product_grid.inc column setting overrides
    &.product-grid--small-cols {
      &-1 {
        @include mantle-grid-columns-1($grid-grid);
      }
      &-2 {
        @include mantle-grid-columns-2($grid-grid);
      }
      &-3 {
        @include mantle-grid-columns-3($grid-grid);
      }
    }
    &.product-grid--medium-cols {
      @include breakpoint($medium-up) {
        &-1 {
          @include mantle-grid-columns-1($grid-grid);
        }
        &-2 {
          @include mantle-grid-columns-2($grid-grid);
        }
        &-3 {
          @include mantle-grid-columns-3($grid-grid);
        }
        &-4 {
          @include mantle-grid-columns-4($grid-grid);
        }
      }
    }
    &.product-grid--large-cols {
      @include breakpoint($large-up) {
        &-1 {
          @include mantle-grid-columns-1($grid-grid);
        }
        &-2 {
          @include mantle-grid-columns-2($grid-grid);
        }
        &-3 {
          @include mantle-grid-columns-3($grid-grid);
        }
        &-4 {
          @include mantle-grid-columns-4($grid-grid);
        }
      }
    }

    /*
     * When mixing. We need to turn off any animations.
     */
    &.mixing .product-grid__content #{$grid-item-selector} {
      opacity: 1;
      transform: none;
      transition: none;
    }
  }
  &.product-grid--carousel {
    margin: 0 auto;
    overflow: hidden;
    @include breakpoint($large-up) {
      padding: 0px;
      overflow: visible;
    }
    .basic-formatter & {
      padding-bottom: 10px;
      @include breakpoint($medium-up) {
        padding-bottom: 30px;
      }
    }
    .slick-list {
      overflow: visible;
      max-width: 100%;
      @include breakpoint($large-up) {
        overflow: hidden;
      }
    }
    // Slick track also needs to be flex.
    .slick-track {
      display: flex;
      flex-wrap: nowrap;
    }
    .product-grid__content {
      padding: 0px 15%;
      display: flex;
      flex-wrap: nowrap;
      overflow: hidden;
      @include breakpoint($large-up) {
        padding: 0px;
      }
      &.slick-initialized {
        overflow: visible;
      }
    }
    #{$grid-item-selector} {
      display: inline-block;
      height: auto;
      padding: 15px;
      flex-shrink: 0; // once inside of .slick-list this won't matter
      @include breakpoint($medium-up) {
        padding: 25px;
      }
      &[aria-hidden='true'] a {
        pointer-events: auto;
        @include breakpoint($medium-up) {
          pointer-events: all;
        }
      }
    }
    .product-grid__item__content {
      height: 100%;
    }

    $noncarousel-grid-item: '.product-grid__content:not(.slick-initialized)' #{$grid-item-selector};

    #{$noncarousel-grid-item} {
      flex-shrink: 0;
    }
    &.product-grid--small-cols {
      @include breakpoint($small-only) {
        &-1 {
          @include flex-grid-columns($noncarousel-grid-item, 1);
        }
        &-2 {
          @include flex-grid-columns($noncarousel-grid-item, 2);
        }
        &-3 {
          @include flex-grid-columns($noncarousel-grid-item, 3);
        }
        &-4 {
          @include flex-grid-columns($noncarousel-grid-item, 4);
        }
      }
    }
    &.product-grid--medium-cols {
      @include breakpoint($medium-only) {
        &-1 {
          @include flex-grid-columns($noncarousel-grid-item, 1);
        }
        &-2 {
          @include flex-grid-columns($noncarousel-grid-item, 2);
        }
        &-3 {
          @include flex-grid-columns($noncarousel-grid-item, 3);
        }
        &-4 {
          @include flex-grid-columns($noncarousel-grid-item, 4);
        }
      }
    }
    &.product-grid--large-cols {
      @include breakpoint($large-up) {
        &-1 {
          @include flex-grid-columns($noncarousel-grid-item, 1);
        }
        &-2 {
          @include flex-grid-columns($noncarousel-grid-item, 2);
        }
        &-3 {
          @include flex-grid-columns($noncarousel-grid-item, 3);
        }
        &-4 {
          @include flex-grid-columns($noncarousel-grid-item, 4);
        }
      }
    }
  }

  &.hide-touts #{$grid-item-selector}[data-inline-content='1'] {
    display: none !important;
  }
  // only do opactiy for carousel
  &--carousel #{$grid-item-selector} {
    transform: none;
  }
  .slick-arrow {
    @include aspect-ratio(30px, 50px);
    transition: opacity 1s;
    @include breakpoint($medium-up) {
      pointer-events: all;
    }
    position: absolute;
    background: rgba(255, 255, 255, 0.5) url(#{$base-theme-path}/img/icons/svg/arrow--right.svg)
      no-repeat;
    background-size: cover;
    backdrop-filter: blur(5px);
    width: 45px;
    height: auto;
    margin-top: 0px;
    transform: translateY(-50%);
    &:before {
      font-size: 30px;
    }
  }
  &:hover .slick-arrow {
    @include breakpoint($large-up) {
      opacity: 1;
    }
  }
  .slick-next {
    right: 0px;
  }
  .slick-prev {
    left: 0px;
    transform: rotate(180deg) translateY(50%);
  }
  .carousel-dots {
    display: none;
  }
}

// Grid delay is triggered via class.
@for $i from 1 through 20 {
  [data-grid-delay='#{$i}'] {
    transition-delay: 0.05s * $i;
  }
}
