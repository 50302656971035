.product-image-container {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: 100%; // placeholder for lazyloading image. product image height/width*100
}

.product-qty-select {
  width: 80px;
  margin-right: 20px;
}

.sku-select-shades,
.sku-select-sizes,
.product-qty-select {
  select,
  .selectBox {
    @include body-small;
    border: 1px solid $color-black;
    height: 45px;
    @include breakpoint($large-up) {
      height: $add-to-bag-button-height--desktop;
    }
  }
  option {
    // line up with
    padding: 0;
  }
  .selectBox {
    font-family: $font--proxima-nova;
    min-width: auto;
    width: 100%;
    &:hover {
      color: $color-black;
    }
    &:before {
      content: '';
      position: absolute;
      @include triangle(12px, $color-black, down);
      transition: transform 500ms;
      transform-origin: 50% 25%;
    }
    &.selectBox-menuShowing {
      &:before {
        @include transform(rotate(180deg));
      }
    }
    .selectBox-label {
      line-height: 45px;
      @include breakpoint($large-up) {
        line-height: 55px;
      }
    }
    .selectBox-arrow {
      display: none;
    }
    &:before {
      margin-top: -3px;
      top: 50%;
      right: 10px;
    }
  }
  &__select-selectBox-dropdown-menu,
  &__selectbox-selectBox-dropdown-menu {
    padding: 0px;
    li a {
      &:hover {
        color: $color-black;
      }
    }
  }
}

// since qty lies with add to bag. sync their height
.product-qty-select {
  .selectBox {
    font-weight: bold;
  }
}

.sku-select-sizes {
  a.selectBox {
    .selectBox-label {
      &:before {
        content: 'Size: ';
        @include font--proxima-nova-bold;
      }
    }
  }
}

.sku-select-shades {
  a.selectBox {
    .selectBox-label {
      &:before {
        content: $shade-text;
        @include font--proxima-nova-bold;
      }
    }
  }
}

.add-to-cart-row {
  display: flex;
  @include breakpoint($large-up) {
    margin-top: 10px;
  }
  .product-qty-select {
    margin-right: 10px;
  }
}

/*
 * Hide arrow and don't interact with if only 0/1 shades
 */
[data-options-count='0'] a.selectBox,
[data-options-count='1'] a.selectBox {
  pointer-events: none;
  &:before {
    display: none;
  }
}

.using-spp-layout .site-content {
  @include breakpoint($large-up) {
    // spacing to uh get over that signup for updates footer block
    padding-bottom: 200px;
  }
}

// this is a container of button states
.product-add-to-cart {
  flex: 1;
}

.product-add-to-waitlist.button {
  @include button--black-pink-alt;
}

.product__inventory-status {
  display: inline;
  text-transform: uppercase;
  @include font--proxima-nova-bold;
  color: $color-pink-accent;
  font-size: 10px;
  @include breakpoint($large-up) {
    font-size: 16px;
  }
  li {
    display: none;
    &.active {
      display: inline-block;
    }
  }
}

.product-add-all-to-bag {
  &__wrapper {
    max-width: 1280px;
    margin: 15px auto;
    text-align: center;
    margin-bottom: 15px;
    .button {
      margin-bottom: 15px;
    }
    .multi-sku-inventory-message {
      @include font--proxima-nova-bold;
      display: none;
      text-transform: uppercase;
      color: $color-pink-accent;
      font-size: 10px;
    }
  }
}

[data-contains-unshoppable][data-is-shoppable],
[data-is-shoppable][data-contains-unshoppable] {
  .multi-sku-inventory-message {
    display: block;
  }
}
