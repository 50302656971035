#loyalty__panel__benefits {
  @include loyalty-panel;
  .loyalty__panel__benefits__title {
    @include loyalty-panel-title;
  }
  .loyalty__panel__benefits__tiers {
    @include loyalty-clear;
  }
  .loyalty__panel__benefits__tiers a {
    position: relative;
    display: block;
    float: left;
    width: 33.3333333333333%;
    text-align: center;
    padding: 10px 10px 20px;
    &:focus,
    &:active {
      outline: none;
    }
  }
  .loyalty__panel__benefits__tiers a.selected:before,
  .loyalty__panel__benefits__tiers a.selected:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    border-top: 0;
    left: 50%;
    bottom: -1px;
  }
  .loyalty__panel__benefits__tiers a.selected:before {
    border-bottom-color: $color-light-gray;
    border-width: 16px;
    margin-left: -16px;
  }
  .loyalty__panel__benefits__tiers a.selected:after {
    border-bottom-color: $white;
    border-width: 15px;
    margin-left: -15px;
  }
  .loyalty__panel__benefits__description {
    padding: 10px;
    border: 1px solid $color-light-gray;
  }
  .loyalty__panel__benefits__tier-points {
    font-weight: bold;
    font-style: italic;
    margin: 10px 0 0;
    em {
      text-decoration: underline;
    }
  }
}
