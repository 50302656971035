///
/// @file template_api/_gnav-util.scss
///
/// \brief Styles for gnav utility links and expandable content wrappers
///
/// @todo audit and add from https://bitbucket.esteeonline.com/projects/DRUPAL/repos/drupal/commits/83ae08c305c25a8b1a32b9a084e785632a23f61e#drupal-7.9/sites/toofaced/themes/toofaced_base/scss/template_api/navigation/_gnav-util.scss
///

.gnav-util__link {
  display: inline-block;
  padding: 0 1em;
}

.gnav-util__icon {
  color: $black;
  display: inline-block;
  position: relative;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  .icon {
    height: 30px;
    width: 30px;
  }
  &__cart-count {
    background: $color-pink;
    border-radius: 50%;
    color: $white;
    position: absolute;
    right: -0.95em;
    width: 20px;
    height: 20px;
    top: -7px;
    font-size: 11px;
    color: $color-gold;
    text-align: center;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:empty {
      visibility: hidden;
    }
    @include breakpoint($medium-up) {
      top: 0;
    }
    @include breakpoint($large-up) {
      color: $white;
    }
  }
}

.gnav-util__nav-fake-target {
  display: inline-block;
  cursor: pointer;
}

.gnav-util__content {
  position: absolute;
  top: 100%;
  width: 100%;
  right: 0;
  background: $color-pink-2;
  z-index: 100;
  display: none;
  @include breakpoint($large-up) {
    width: 410px;
    top: 58px; // Magic number for visual alignment
    .sticky-nav-active & {
      top: 48px;
    }
  }
  &-close {
    background: transparent;
    position: absolute;
    z-index: 2;
    right: 1em;
    top: 1em;
    text-decoration: none;
    width: 22px;
    height: 22px;
    cursor: pointer;
    .icon {
      fill: $color-gold;
      height: 22px;
      width: 22px;
    }
    &:hover {
      text-decoration: none;
    }
    &-inner {
      padding: 1em;
    }
  }
  &-scroller {
    overflow-y: auto;
    height: 450px;
  }
}

.gnav-util--search {
  .gnav-util__content {
    position: absolute;
    top: 100%;
    width: 100%;
    padding-bottom: 26px;
    @include breakpoint($large-up) {
      top: $sticky-height;
      width: 410px;
      padding-bottom: 15px;
    }
    &--search {
      @include breakpoint($large-up) {
        &::before {
          content: ' ';
          bottom: 12px;
          position: absolute;
          left: 0;
          background: url(#{$base-theme-path}img/gradient-border.png) repeat-x;
          height: 7px;
          width: 100%;
        }
      }
      .search-icon {
        display: none;
        @include breakpoint($medium-down) {
          position: absolute;
          left: 20px;
          display: block;
          width: 20%;
          max-width: 28px;
          opacity: 0.6;
          padding-top: 6px;
        }
      }
      .esearch-nav {
        padding-bottom: 6px;
        .esearch-nav__form {
          min-height: 40px;
          position: relative;
          padding: 0;
        }
      }
      .gnav-util__content-inner {
        @include breakpoint($medium-down) {
          padding: 0.5em 0 0 0;
          margin-bottom: 1.25em;
        }
      }
      .gnav-util__content-header {
        font-weight: 600;
        margin-bottom: 16px;
        display: block;
      }
      .gnav-util__content-recent-searches {
        padding: 0 24px;
        .js-end-recent-searches dd a {
          line-height: 26px;
        }
      }
      @include breakpoint($large-up) {
        position: fixed;
        left: 0;
        top: 110px;
        width: 100%;
      }
    }
  }
}

img.gnav-util__avatar {
  width: 30px;
  height: 30px;
  vertical-align: middle;
}

.esearch-nav__form-fields {
  text-align: center;
}

.esearch-nav input[type='submit'] {
  display: none;
}

.esearch-nav__suggestions-wrapper {
  padding: 0 20px;
}
