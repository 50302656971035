/* Dropdown control */
@mixin selectBox {
  // font-size: $base-font-size;
  font-size: 11px;
  letter-spacing: 0.05em;
  text-decoration: none;
  text-align: left;
  padding-right: 10px;
  position: relative;
  border: 1px solid $color-pink-1;
  border-radius: 0;
  color: $color-black;
  outline: none;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
  height: 40px;
  line-height: 37px;
  background: $color-white;
  transition: background 0.3s, color 0.3s, border-color 0.3s;
}

a.selectBox {
  @include selectBox;
  .selectBox-label {
    padding: 0 40px 0 15px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
  }
  .selectBox-arrow {
    background: url(#{$base-theme-path}/svg-icons/src/arrow--down.svg) no-repeat 50%;
    background-size: 16px;
    background-color: transparent;
    color: $color-dark-gray;
    position: absolute;
    top: 0;
    right: 8px;
    height: 100%;
    min-width: 30px;
    font-size: 9px;
    &:after {
      top: 36%;
      position: absolute;
      left: 50%;
      font-weight: 700;
    }
  }
  &.selectBox-menuShowing {
    .selectBox-arrow {
      background-image: url(#{$base-theme-path}/svg-icons/src/arrow--up.svg);
    }
  }
}
/* Dropdown menu */
.selectBox-dropdown-menu {
  position: absolute;
  z-index: 99999;
  min-height: 1em;
  max-height: 200px;
  border: 1px solid $color-lighter-gray;
  background: $color-white;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
/* Options */
@mixin selectBox-options {
  font-size: $base-font-size;
  text-decoration: none;
  text-align: left;
  list-style: none;
  display: block;
  margin: 0;
  cursor: pointer;
  overflow: auto;
  li {
    a {
      display: block;
      color: $color-black;
      padding: 10px 15px;
      white-space: nowrap;
      overflow: hidden;
      border: 0;
      text-decoration: none;
      transition: none;
      font-weight: bold;
      font-size: 14px;
      transition: all 0.3s;
      &:hover {
        background: $color-pink-2;
        color: $color-black;
      }
    }
    &.selectBox-selected a {
      color: $color-black;
    }
    &.selectBox-disabled a {
      color: $color-light-gray;
      &:hover {
        background: $color-pink-2;
        cursor: default;
      }
    }
    &.selectBox-selected.selectBox-disabled a {
      background: $color-white;
      color: $color-light-gray;
      &:hover {
        background: $color-white;
        cursor: default;
      }
    }
  }
}

.selectBox-options {
  @include selectBox-options;
}

a.selectBox.selectBox--alt {
  border: none;
  font-size: 12px;
  font-weight: normal;
  line-height: 1;
  height: auto;
  padding-left: 0;
  width: auto;
  &:before {
    right: 0;
  }
  .selectBox-label {
    line-height: 1.5;
    padding: 0 20px 0 0;
  }
}

.selectBox--alt-selectBox-dropdown-menu {
  min-width: 110px;
}
