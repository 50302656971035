.form-indicator-menu {
  color: $color-darkest-gold;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 30px 10px 10px;
  &__item {
    font-size: 10px;
    text-transform: uppercase;
    display: flex;
    max-width: 130px;
    // All but the last item have a line separator built in.
    flex: 1;
    &:last-child {
      flex: 0;
    }
    @include breakpoint($medium-up) {
      font-size: 12px;
    }
    &.active {
      // unset the mantle boldness
      font-weight: normal;
      .form-indicator-menu__item__link::before {
        background: url(#{$base-theme-path}img/icons/svg/heart--filled_gold.svg) no-repeat 50%;
      }
    }
    &__link {
      cursor: pointer;
      padding-bottom: 15px;
      display: flex;
      flex-direction: column;
      text-decoration: none;
      align-items: center;
      position: relative;
      span {
        color: $color-black;
        position: absolute;
        bottom: 0;
        white-space: nowrap;
      }
      &:hover {
        text-decoration: none;
      }
      &::before {
        background: url(#{$base-theme-path}img/icons/svg/heart--outline_gold.svg) no-repeat 50%;
        background-size: 35px;
        content: "";
        display: block;
        height: 35px;
        margin: 0 8px;
        padding-bottom: 15px;
        width: 35px;
      }
    }

    // This is the separator gold line
    &:after {
      flex: 1;
      content: ' ';
      width: 100%;
      border-top: 1px solid $color-darkest-gold;
      margin-top: 10px;
      display: inline-block;
      @include breakpoint($medium-up) {
        margin-top: 15px;
        width: 80px;
      }
    }
    &:last-child:after {
      display: none;
    }
  }
}
