.mpp-filter-set {
  &__header {
    display: flex;
    padding: 20px 0;
    justify-content: space-between;
    @include breakpoint($large-up) {
      display: none;
    }
    @include breakpoint($medium-portrait-only) {
      display: flex;
    }
  }
  &__reset {
    background: transparent;
    cursor: pointer;
    order: 0;
    color: $color-black;
    text-decoration: none;
    &:hover,
    &:active {
      text-decoration: none;
    }
    @include breakpoint($large-up) {
    }
  }
  &__item-count__container {
    order: 1;
    text-transform: uppercase;
    &[data-grid-count='1'] {
      .plural {
        display: none;
      }
    }
  }
  &__body {
    position: relative;
    @include breakpoint($large-up) {
      top: -10px;
    }
    @include breakpoint($medium-portrait-only) {
      top: 0;
    }
  }
  &__category {
    margin-bottom: 20px;
  }
  &__category-links {
    display: flex;
    flex-direction: column;
  }
  &__close {
    display: none;
    .icon--close {
      width: 16px;
    }
  }
  &__footer {
    padding: 20px 0;
    justify-content: space-between;
    display: none;
    @include breakpoint($large-up) {
      display: flex;
    }
  }
  &__category-name {
    @include font--proxima-nova-bold;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.1em;
    margin-bottom: 6px;
  }
  .filter-link {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    color: $color-black;
    text-decoration: none;
    position: relative;
    margin-bottom: 9px;
    align-items: center;
    &:last-child {
      margin-bottom: 0;
    }
    &__text {
      @include font--proxima-nova;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      font-size: 14px;
      line-height: 16px; // this largely controls how far below the border is
      letter-spacing: 0.08em;
      text-decoration: none;
      color: $color-black;
      transition: color 0.3s ease;
      width: 100%;
      word-break: break-word;
      .icon {
        width: 18px;
        height: 16px;
        fill: transparent;
        use {
          stroke: $color-black;
          stroke-width: 3%;
        }
      }
      &:hover {
        .icon {
          use {
            stroke: $color-pink-accent;
          }
        }
      }
    }
    &.active .filter-link__text {
      .icon {
        fill: $color-pink-accent;
        use {
          stroke: $color-pink-accent;
        }
      }
    }
  }
  &__title {
    display: none;
  }
}

.filter-selected {
  display: none;
}

.mpp-filter__container {
  margin: 10px;
}
