/**
 * Generic typeahead
 */
.typeahead-wrapper {
  .typeahead-suggestions__item {
    padding: 4px 0;
  }
  .product-grid__content {
    @include breakpoint($medium-up) {
      border-top: 0;
    }
  }
  .esearch-nav-products {
    @include breakpoint($mobile-landscape) {
      display: flex;
      padding: 10px;
    }
    @include breakpoint($medium-up) {
      max-width: $max-width;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      .esearch-product--typeahead {
        float: left;
        width: 20%;
        .esearch-product {
          &__headline,
          &__subline {
            a {
              color: $color-black;
              text-align: center;
              display: block;
              font-weight: 600;
            }
          }
        }
        .esearch-product__subhead a {
          color: $color-black;
          text-align: center;
          display: block;
        }
        .product__image {
          max-width: 200px;
          margin: 0 auto;
          display: block;
        }
      }
    }
  }
  // @setup new brand - this can be removed
  // temp image FPO
}

/**
 * Search form in page content - special positioning
 */
.site-content {
  .search-form {
    position: relative;
    .typeahead-wrapper {
      position: absolute;
      z-index: 999;
      background-color: $color-white;
      border: $color-black solid 2px;
      padding: 1em;
    }
  }
}

.esearch-nav__popular {
  .esearch-nav__popular-header {
    color: $color-gold;
    display: inline-block;
    margin-bottom: 6px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .esearch-nav__popular-list li {
    display: block;
    font-weight: 400;
    line-height: 1.7;
  }
  .typeahead-wrapper {
    text-align: center;
  }
}
