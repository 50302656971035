// Try to make header non peeking on quiz pages.
// note the body class is set in the quiz formatter
body.page-foundation-finder:not(.sticky-nav-active) {
  .site-header .site-header__wrapper {
    padding-bottom: 5px;
    @include breakpoint($medium-up) {
      padding-bottom: 10px;
    }
  }
}

.mantle-ajax-form-options-field {
  position: static;
  &__spinner {
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    z-index: 5;
    align-items: center;
    justify-content: center;
    .quiz-landing-page-formatter__loading__bg-image {
      width: auto;
      height: auto;
      opacity: 1;
      max-width: 70px;
    }
  }
  &.ajax-loading &__spinner {
    display: flex;
  }
}

.foundation-finder-quiz {
  color: $color-dark-gray;
  position: relative;

  // Top box shadow
  &:before {
    content: '';
    box-shadow: 1px 0 16px $color-ff-top-menu-shadow;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    // matching old toofaced.com header height
    // for box shadow
    height: 75px;
    transform: translateY(-100%);
    pointer-events: none;
  }

  // ajax form options
  .js-mantle-ajax-form-options-field {
    min-height: 300px;
  }
  .form-formatter {
    max-width: 1050px;
    margin: 0 auto;
    &__form {
      position: relative;
      background: $color-ff-reco-pink-1;
    }
  }
  .mantle-form-options-field {
    &__header {
      .style--bare {
        @include font--proxima-nova;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 1px;
        @include breakpoint($medium-up) {
          font-size: 40px;
          line-height: 40px;
        }
        .madina-title {
          @include madina-title;
        }
        p:last-child {
          margin: 0px;
        }
      }
    }
    &__subheader {
      .style--bare {
        @include font--proxima-nova;
        line-height: 1.385em;
        text-transform: uppercase;
        font-size: 10px;
        margin: 13px 0px 16px;
        letter-spacing: 0.03em;
        @include breakpoint($medium-up) {
          margin: 18px 0px;
          font-size: 18px;
        }
        p:last-child {
          margin: 0px;
        }
      }
    }
    &__footer {
      text-align: center;
      transform: translateY(-33%);
      @include breakpoint($medium-up) {
        transform: translateY(-75%);
      }
      &-cta {
        margin-top: 0;
        .button {
          font-weight: 400;
          width: 200px;
          min-width: auto;
          min-height: 2rem;
          color: $color-darkest-gold;
          box-shadow: 5px 5px 0 $color-darkest-gold;
        }
      }
    }
    &__item {
      width: 50%;
      margin-bottom: 10px;
      @include breakpoint($medium-up) {
        width: auto;
      }
    }
    &__items {
      flex-wrap: wrap;
      .range-slider-carousel {
        margin-top: 0px;
        margin-bottom: 0px;
      }
    }
  }
  .mantle-form__checkbox {
    color: $color-black;
    max-width: 190px;
    margin: 0px 10px;
    &__image {
      margin-bottom: 10px;
      width: 65px;
      height: 65px;
      @include breakpoint($medium-up) {
        margin-bottom: 20px;
        width: 81px;
        height: 81px;
      }
      &--color {
        transition: border 0.3s ease;
        overflow: hidden;
        border-radius: 50%;
        border-color: transparent; // needed for transition
        box-sizing: border-box;
      }
    }
    &:hover .mantle-form__checkbox__image--color,
    &.active .mantle-form__checkbox__image--color {
      border: 2px solid $color-ff-finish-box-hover;
    }
    label {
      @include font--proxima-nova;
      font-weight: 600;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      font-size: 10px;
      @include breakpoint($medium-up) {
        font-size: 14px;
      }
    }
    &__description {
      @include font--proxima-nova;
      line-height: 1.385em;
      letter-spacing: 0.03em;
      font-size: 9px;
      margin: 1em 0px;
      padding: 0 15px;
      max-width: 190px;
      @include breakpoint($medium-up) {
        font-size: 13px;
        padding: 0 10px 0 9px;
      }
      p {
        margin: 0px;
      }
    }
  }
  .mantle-form-options-field--input-style--foundation_finder_box {
    .mantle-form-options-field {
      &__items {
        display: flex;
        justify-content: center;
      }
      &__item {
        width: 50%;
        max-width: 500px;
        padding: 0px 5px;
        margin-bottom: 25px;
        @include breakpoint($medium-up) {
          padding: 0px 20px;
          width: auto;
          flex: 1;
        }
      }
    }
    .mantle-form__checkbox {
      background: $color-white;
      border: 2px solid transparent;
      max-width: none;
      text-align: center;
      transition: border-color 0.3 ease;
      box-shadow: 5px 5px 0 $color-ff-finish-box-shadow;
      height: 100%;
      justify-content: space-between;
      @include breakpoint($medium-up) {
        box-shadow: 10px 10px 0 $color-ff-finish-box-shadow;
        margin: 0px;
      }
      &:hover,
      &.active {
        border-color: $color-ff-finish-box-hover;
      }
      label {
        flex: 0;
        order: 0;
        font-size: 10px;
        margin-top: 20px;
        @include breakpoint($medium-up) {
          font-size: 20px;
        }
      }
      &__description {
        order: 1;
        max-width: 255px;
        flex: 1;
      }
      &__image {
        flex: auto;
        order: 2;
        width: auto;
        height: auto;
        max-width: 95%;
        max-height: 95%;
        margin-bottom: 5px;
      }
    }
  }
}
