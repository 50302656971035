///
/// @file components/prouducts/_spp.scss
///
/// \brief Styles for multi-product pages
///
///

body.page-product {
  padding-bottom: 120px;
  @include breakpoint($medium-up) {
    padding-bottom: 90px;
  }
  @include breakpoint($large-up) {
    padding-bottom: 0;
  }
}

.spp__container {
  .content-formatter,
  .spp-cross-sells,
  .spp-reviews,
  > .block {
    margin-top: mobilePx(40px);
    @include breakpoint($medium-up) {
      margin-top: tabletPx(40px);
    }
    @include breakpoint($landscape-up) {
      margin-top: 40px;
    }
  }
}
