@keyframes ring-around {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes quiz-loading {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateX(0deg);
  }
  50% {
    transform: perspective(120px) rotateY(-180.1deg) rotateX(0deg);
  }
  100% {
    transform: perspective(120px) rotateY(-360deg) rotateX(0deg);
  }
}

.quiz-landing-page-formatter {
  &__quiz-form {
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
  &.show-quiz &__quiz-form {
    transition: opacity 0.5s ease;
    opacity: 1;
    height: auto;
  }
  &__results {
    transition: opacity 0.5s ease;
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
  &.show-results &__results {
    opacity: 1;
    height: auto;
    overflow: visible;
  }
  &__items {
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
  &.show-quiz &__items,
  &.show-results &__items {
  }
}

.quiz-landing-page-formatter {
  &__loading {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    display: none;
    margin: 0px auto;
    width: calc(100vw - 20px);
    height: calc(100vw - 20px);
    max-width: 500px;
    max-height: 500px;
    &__bg-image {
      width: 100%;
      height: 100%;
      opacity: 0.7;
    }
    &__text {
      text-align: center;
      padding: 40px;
      @include breakpoint($medium-up) {
        padding: 65px;
      }
      .style--bare {
        @include font--bodoni-bold;
        color: $color-white;
        font-size: 30px;
        @include breakpoint($medium-up) {
          font-size: 40px;
        }
      }
    }
    &__content {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        margin: 0px;
      }
    }
    #colorbox & {
      display: block;
    }
    /*
    &:after {
      position: absolute;
      top: 0px;
      left: 0px;
      content: " ";
      display: block;
      width: 100%;
      height: 100%;
      margin: 1px;
      border-radius: 50%;
      border: 5px solid transparent;
      border-color: rgba(246, 80, 151, .5) transparent transparent transparent;
      animation: ring-around 1.5s linear infinite;
    }
    */
    &__status {
      text-align: center;
      width: 100%;
    }
  }
}
