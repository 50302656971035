$icon: 90px; //wrapper
$icon-size: 32px; // play icon
$icon-pad: 5px; // pad for progress

.videojs-video__play.video-play-trigger {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: $color-overlay-75;
  border-radius: 50%;
  height: $icon;
  left: calc(50% - #{$icon} / 2);
  top: calc(50% - #{$icon} / 2);
  width: $icon;
  z-index: 100;
  transform: none;
  &:before {
    content: ' ';
    background: url(#{$base-theme-path}svg-icons/src/play.svg) no-repeat;
    display: block;
    height: $icon-size;
    left: calc(50% - #{$icon-size} / 2);
    position: absolute;
    top: calc(50% - #{$icon-size} / 2);
    width: $icon-size;
  }
  &:after {
    margin: 0;
    width: $icon-size;
    height: $icon-size;
  }
  &:hover {
    transform: none;
  }
}

// Override platform mantle_video styles as needed here
// this is inside the player
.videojs-video {
  .videojs {
    .vjs-play-progress {
      &:before {
        font-size: 12px;
        color: $color-pink;
      }
    }
  }
}

#cboxContent {
  .videojs-video {
    &.videojs-video--loading .videojs-video__landing {
      .video-play-trigger:after {
        height: 100%;
        left: -11px;
        top: -11px;
        width: 100%;
      }
    }
  }
}

.video-icon-style--light_icon_gold_background {
  .videojs-video__play {
    background: url(#{$base-theme-path}/img/icons/svg/play--gradient-circle.svg) no-repeat;
    background-size: contain;
  }
  .video-play-trigger {
    &:before {
      display: none;
    }
  }
}

.video-icon-style--gold_icon_light_background {
  .videojs-video__play {
    background-color: $color-overlay-75;
  }
  .video-play-trigger {
    &:before {
      background: url(#{$base-theme-path}/img/icons/svg/play--gradient.svg) no-repeat;
    }
  }
}

// Icon only display:
// hide poster image and only display the icon in some cases
// added as a wrapper class around standard mantle video
.video--icon-only {
  height: $icon;
  left: calc(50% - #{$icon} / 2);
  position: absolute;
  top: calc(50% - #{$icon} / 2);
  width: $icon;
  z-index: 3;
  @at-root [data-video-icon-placement='bottom_right'] & {
    @include breakpoint($medium-up) {
      position: absolute;
      top: auto;
      left: auto;
      bottom: 0;
      right: 0;
    }
  }
  .video-js {
    background: none;
  }
  .videojs-video {
    position: relative;
    background: transparent;
    padding-top: 0;
  }
  .videojs-video__landing {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: $icon;
    width: $icon;
    z-index: 1;
  }
  .videojs-video__landing__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: $icon;
    height: $icon;
  }
  .videojs-video__landing__image img {
    display: none;
    transform: none;
  }

  // There is a one-off rule in _content_blocks.scss that needs to be matched.
  @at-root .padding-vertical--bare > .padding-vertical--bare & {
    @include breakpoint($medium-up) {
      margin-bottom: tabletPx(-10px);
    }
    @include breakpoint($landscape-up) {
      margin-bottom: -10px;
    }
  }
}

.video-product-cuepoint {
  background-color: $color-overlay-75;
  // quickshop is abs pos and need to have it's own background
  &.quickshop-active {
    background: none;
    .product-brief {
      opacity: 0;
    }
    .hide-on-quickshop {
      opacity: 0;
    }
  }
  &__header {
    @include header-medium;
    font-size: 12px;
    text-align: center;
    margin: 5px 0;
    p {
      margin: 0;
    }
  }
  &__body {
    font-size: 12px;
    text-align: center;
    margin: 5px 0;
  }
  .product-brief {
    &.active {
      opacity: 0;
    }
    .product-shade-picker {
      opacity: 1;
      width: auto;
      transform: none;
    }
    &__rating-favorites-wrapper {
      display: none;
    }
    &__footer {
      margin-top: 16px;
    }
  }
  .product-quickshop {
    top: auto;
    bottom: 0;
    &__container {
      background-color: $color-overlay-75;
    }
  }
  .product-add-to-cart {
    margin: 15px 0 7px;
    &__button {
      text-decoration: underline;
      .icon {
        display: none !important;
      }
    }
  }
}

#colorbox.colorbox--video {
  #cboxLoadedContent {
    padding: 0;
    margin: auto;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    height: auto !important;
    z-index: 100;
  }
  .videojs-video {
    z-index: 100;
  }
  video {
    display: block;
    width: 100%;
    max-height: 100%;
  }
}

/*
 * Replicate the spinning pink circle normally found on the landing play icon.
 * The .vjs-loading-spinner shows up on the video itself, which is usually
 * hidden when playing inline.
 */
.vjs-loading-spinner {
  border: 0;
  &:before {
    display: none;
  }
  &:after {
    position: absolute;
    top: -5px;
    left: -5px;
    content: ' ';
    display: block;
    margin: 0;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    border-radius: 50%;
    border: 11px solid transparent;
    border-color: $color-pink transparent transparent transparent !important;
    animation: video-loading-circle 1.5s linear infinite;
  }
  // Hide loading spinner for mobile
  @include breakpoint($medium-down) {
    display: none !important;
  }
}

.vjs-fullscreen-control {
  // Hide full screen button for mobile
  @include breakpoint($medium-down) {
    display: none !important;
  }
}

// Eliminates double loader animation on some player instances
.js-videojs-player {
  .vjs-waiting .vjs-loading-spinner {
    display: none;
  }
}
