///
/// @file template_api/endeca/_esearch_forms.scss
///
/// \brief Search Block for site header area; form on search page
///
/// @todo test in header and redo styles
/// @setup new brand - these styles can be customized per design
///

// @todo check main variables, see if we need them here

$mobile_search_header_height: 60px;

.search-form {
  width: 100%;
  //   @include breakpoint($medium-up) {
  //     padding: 25px 43px 25px 25px;
  //     width: 744px;
  //   }
  &__fields {
    @include pie-clearfix;
  }
  input[type='text'].esearch-nav__form-field {
    @include input-placeholder {
      letter-spacing: 2px;
      font-size: 16px;
      line-height: 1;
      color: $color-black;
      opacity: 0.2;
    }
    text-transform: uppercase;
    background: transparent;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid $color-gold;
    padding: 0 60px;
    letter-spacing: 2px;
    font-size: 16px;
    @include breakpoint($medium-up) {
      @include input-placeholder {
        font-size: tabletPx(30px);
      }
      font-size: tabletPx(30px);
    }
    @include breakpoint($large-up) {
      @include input-placeholder {
        font-size: 30px;
      }
      max-width: 400px;
      margin: auto;
      font-size: 30px;
    }
  }
  input[type='text'].search-form__field,
  input[type='submit'].search-form__submit {
    display: block;
  }
  input[type='text'].search-form__field {
    width: 100%;
    background: transparent;
    height: auto;
    padding: 0;
    text-transform: uppercase;
    &:focus {
      border-color: $color-black;
    }
    @include breakpoint($medium-up) {
      border: 1px solid $color-black;
      //width: $pc-search-width;
      padding: 0 7px;
      float: left;
      &:focus {
        border-color: $color-black;
      }
    }
  }
  .right-caret {
    padding-left: 10px;
  }
  input[type='submit'].search-form__submit {
    display: block;
    margin: 0 0 0 5px;
    float: left;
  }
  //   &__results {
  //     @include breakpoint($medium-up) {
  //       //max-width: $pc-search-width;  // width of text field
  //     }
  //   }
  .typeahead-wrapper {
    text-align: left;
    width: 100%;
  }
  .search-suggestions__link {
    font-size: 14px;
    display: block;
    padding: 0 7px 0 0;
    text-transform: uppercase;
    @include breakpoint($medium-up) {
      padding: 0 5em;
      text-align: center;
    }
  }
  .result {
    padding: 10px 0;
    a,
    a:active {
      text-decoration: none;
      color: $color-black;
    }
    @include breakpoint($mobile-landscape) {
      padding: 0 5px;
    }
  }
  .result.search-suggestions__item {
    @include breakpoint($medium-down) {
      width: 100%;
      padding-top: 63px;
      text-align: center;
    }
  }
  &--results {
    margin: 0 auto;
    position: relative;
    // background-color: #fff;
    min-height: $mobile_search_header_height; // Same height as .search-results__header--mobile. We do this so that it completely covers it.
    @include breakpoint($medium-up) {
      min-height: auto;
    }
    .search-form__results-count {
      display: none;
      position: absolute;
      border-top: solid 1px $color-black;
      border-bottom: solid 1px $color-black;
      right: auto;
      width: 100%;
      text-align: center;
      padding: 0 12px;
      @include breakpoint($medium-up) {
        //right: 169px;
        width: auto;
        text-align: left;
      }
    }
  }
}

// Search results page:
.search-results {
  padding: 0;
  @include breakpoint($medium-up) {
    padding: 0 20px;
  }
}
// @todo check styles
.search-results__header--mobile {
  display: none;
  background: $color-light-gray;
  text-align: center;
  height: $mobile_search_header_height;
  line-height: $mobile_search_header_height;
}

.search-results__header--desktop {
  padding: 0 1em;
  display: none;
}

@include breakpoint($medium-up) {
  .search-results__header--mobile {
    display: none;
  }
}

// @todo check styles
// we may need logic like this?
// .endeca-product-result {
//   .product__button--add-to-bag {
//     display: none;
//   }
//   // &--1 means shaded, &--0 means un-shaded
//   &--0 {
//     @include breakpoint($small-down) {
//       .product__button--add-to-bag {
//         display: inline-block;
//       }
//       .product__button--quickshop {
//         display: none;
//       }
//     }
//   }
// }
