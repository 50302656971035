.range-slider-carousel__style--smooshie {
  .range-slider-carousel {
    .slide-selected-finished {
      cursor: pointer;
    }
    &__content-well {
      text-align: center;
      bottom: 0px;
      width: 100%;
      max-width: 40%;
      max-height: 90px;
      transform: translateY(-35%);
      @include breakpoint($medium-up) {
        max-width: 100%;
        max-height: 170px;
      }
      .slick-list {
      }
      .range-slider-item__image {
        overflow: visible;
        &:before {
          display: none;
        }
        .mantle-media-asset {
          padding: 0 10px;
          @include breakpoint($medium-up) {
            display: inline-block;
            height: auto;
            width: auto;
            padding: 0;
          }
          img {
            max-height: 120px;
            width: auto;
            height: auto;
            @include breakpoint($medium-up) {
              width: auto;
              max-height: 200px;
            }
          }
        }
      }
      .slick-slide {
        display: block;
        opacity: 0;
        transition: opacity 0.8s;
      }
      .slick-slide.slick-current {
        opacity: 1;
      }
    }
  }
}
