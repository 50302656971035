//Need to override the base changes, so ID selector and all classes used for global code
#power_review_container {
  .email_check {
    .pr_email {
      width: 70%;
      @include breakpoint($medium-up) {
        width: 74.5%;
      }
      @include breakpoint($landscape-up) {
        width: 35.5%;
      }
    }
  }
  .full_sign_in {
    .pr_row_email,
    .pr-row_password {
      .form-text {
        padding: 0 10px;
        width: 69.5%;
        @include breakpoint($medium-up) {
          width: 73.5%;
        }
        @include breakpoint($landscape-up) {
          width: 35.5%;
        }
      }
    }
  }
  .p-w-r {
    #pr-war-form {
      .form-group {
        .btn-group {
          .pr-btn {
            &.active {
              color: $color-white;
            }
          }
        }
      }
    }
  }
}

.spp-reviews {
  #spp-reviews-display {
    .p-w-r {
      .pr-review-snapshot-snippets {
        .pr-snippet-write-review-link {
          line-height: 1.5;
        }
      }
    }
  }
}

// Need to Overwrite the styles for the cookies popup.
#onetrust-consent-sdk {
  #onetrust-banner-sdk {
    #onetrust-close-btn-container {
      right: 7px;
      top: 7px;
    }
  }
}

.search-wrapper {
  &__results {
    .results__header--column {
      width: 98%;
      @include breakpoint($medium-up) {
        width: 15%;
      }
    }
    .product-grid-wrapper {
      padding-bottom: 10%;
    }
  }
  &__sort {
    .mpp-sort__label {
      right: 1%;
      margin-right: 0;
    }
  }
}

.store-locator {
  &__stores {
    .store-locator {
      &__tooltip-name {
        font-size: 13px;
        @include breakpoint($medium-up) {
          font-size: 15px;
        }
      }
    }
  }
}

.email-signup-custom {
  &__gdpr-link {
    display: block;
    &:hover {
      .email-signup-custom {
        &__gdpr-overlay {
          width: 100%;
          @include breakpoint($medium-up) {
            width: 80%;
          }
        }
      }
    }
  }
  &--large-cols-2 {
    .email-signup-custom {
      &__visible-inputs {
        justify-content: center;
        margin-top: 10px;
        .input-container {
          width: 100%;
          margin-right: 0;
          @include breakpoint($medium-up) {
            width: 50%;
          }
          input {
            .adpl--processed {
              padding: 0 15px;
            }
          }
        }
      }
    }
  }
}

.form-formatter {
  &__indicator {
    .form-indicator-menu {
      &__item {
        &__link {
          span {
            white-space: pre-wrap;
            top: 25px;
            @include breakpoint($medium-up) {
              white-space: nowrap;
              top: 35px;
            }
          }
        }
      }
    }
  }
}

.waitlist-form {
  &__form {
    .waitlist-form {
      &__button-row {
        &__field {
          input[type='email'] {
            width: 90%;
            padding: 15px 15px 0;
            @include breakpoint($landscape-up) {
              height: 29px;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.foundation-finder-result {
  .add-to-cart-row {
    .product {
      &__button--add-to-bag {
        line-height: 1.5;
      }
    }
  }
}

.foundation-finder-quiz {
  .mantle-form {
    &__checkbox {
      &__description {
        display: block;
      }
    }
  }
}

.product-full__offer__content {
  display: none;
}

span.link-like {
  cursor: pointer;
  color: $color-link;
  text-decoration: underline;
}

.product-full__footer {
  .product-full__cta {
    display: flex;
    padding: 0 5px 20px;
    align-items: flex-end;
  }
  .product-full__cta.waitlist-form {
    padding: 0 5px;
  }
}

.checkout-panel--bottom-viewcart-buttons {
  display: block;
}
