///
/// @file components/prouducts/_mpp.scss
///
/// \brief Styles for multi-product pages
///
///

// might be good to move this out to apply to every site-content
body.using-mpp-layout .site-content {
  position: relative;
}

// the fitler js will set the body to the height of filters
// this is only for mobile
body.filters-open,
body.sorter-open {
  @include breakpoint($medium-down) {
    overflow: hidden;
  }
}

.field-elc-mpp-content {
  > .content-block {
    max-width: 100%;
  }
  > .basic-noderef,
  > .div {
    margin-bottom: mobilePx(30px);
    @include breakpoint($medium-up) {
      margin-bottom: tabletPx(40px);
    }
    @include breakpoint($large-up) {
      margin-bottom: 40px;
    }
  }
  > .basic-read-more {
    border-top: none;
  }
}

.mpp-container {
  margin: 0 10px 0 10px;
  &:after {
    content: ' ';
    background: $color-black;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 2;
    display: none;
  }
  &.filters-open {
    &:after {
      display: block;
      @include breakpoint($large-up) {
        display: none;
      }
      @include breakpoint($medium-portrait-only) {
        display: block;
      }
    }
  }
  &.sorter-open {
    &:after {
      display: block;
      @include breakpoint($large-up) {
        display: none;
      }
    }
  }
  &__primary {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 20px auto 0px auto;
    // matching grid with filter. Since grid doesn't ahve gutters.
    // we need to calcualte using the padding
    // so 80vw = x - x * 5.96%
    @include breakpoint($large-up) {
      max-width: 1200px;
      flex-direction: row;
      margin: 0 auto;
    }
    @include breakpoint($medium-portrait-only) {
      margin: 20px auto 0px auto;
    }
    // unset the default grid max-wdith here
    // and let mpp define sizing
    .product-grid {
      .product-grid__content {
        max-width: none;
        margin: 0px;
        // MPPs should always start left align even if not enough to fill up
        // row
        &:not(.slick-initialized) {
          justify-content: flex-start;
        }
      }
    }
  }
  &__filters {
    position: absolute;
    padding: 45px 25px 25px 25px;
    background: #fff5f5;
    transition: all 0.5s ease;
    transition-property: opacity, transform, clip-path;
    visibility: visible;
    opacity: 0;
    left: 50%;
    overflow: hidden;
    transition-delay: 0s;
    width: 100%;
    max-width: 80vw;
    //clip-path: inset(0px 0px 100% 0px);
    @include transform(translate(-50%, -50%));
    pointer-events: none;
    z-index: 11;
    @include breakpoint($large-up) {
      width: 0;
      height: 0;
      position: static;
      @include transform(translate(-30px, 0));
      clip-path: none;
      padding: 0px;
      display: block;
      visibility: visible;
      max-width: none;
    }
    @include breakpoint($medium-portrait-only) {
      @include transform(translate(-50%, -50%));
      position: absolute;
      padding: 45px 25px 25px 25px;
      max-width: 80vw;
    }
  }
  &__grid {
    transition: all 0.5s ease;
    width: 100%;
    transition-delay: 0.5s;
  }
  .filter-toggle {
    border: 0;
    background: transparent;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 1em;
    color: $color-dark-gray;
    &:hover {
      text-decoration: none;
    }
    .filter-icon {
      width: 28px;
      height: 10px;
      position: relative;
      margin-left: 1em;
      color: $color-dark-gray;
      flex-wrap: nowrap;
      order: 1;
      .line1,
      .line2 {
        top: 10px;
        left: 0;
        transition: all 0.5s ease;
        position: absolute;
        border-bottom: 1px solid $color-dark-gray;
      }
      .line1 {
        width: 28px;
        transform: none;
        margin-top: 2px;
      }
      .line2 {
        width: 18px;
        transform: none;
        margin-top: -2px;
      }
      .state-toggled,
      .state-init {
        color: $color-dark-gray;
        text-decoration: none;
      }
    }
    .state-toggled {
      display: none;
    }
  }
  &.filters-open .filter-toggle {
    z-index: 12;
    margin: 0 auto;
    line-height: mobilePx(80px);
    height: mobilePx(80px);
    min-width: mobilePx(310px);
    background: $color-black;
    color: $color-white;

    // on ipad and below, we show toggled filter as button
    // we use down breakpoints because we don't want button mixin stuff to
    // messed up desktop
    @include breakpoint($medium-down) {
      @include button;
      min-width: mobilePx(310px);
    }
    @include breakpoint($medium-portrait-only) {
      @include button;
      min-width: mobilePx(310px);
    }
    @include breakpoint($large-up) {
      margin-left: 0;
      cursor: pointer;
      display: flex;
      font-size: 14px;
      text-transform: uppercase;
      line-height: 22px;
      font-weight: bold;
      letter-spacing: 0.15em;
      padding: 1em;
      color: $color-dark-gray;
      &:hover {
        color: $color-dark-gray;
        text-decoration: none;
      }
    }
    @include breakpoint($medium-portrait-only) {
      margin-left: auto;
      text-transform: uppercase;
      height: auto;
      display: inline;
      &:hover {
        background-color: $color-pink-accent;
        color: $color-white;
      }
    }
    &:active,
    &:hover {
      opacity: 1;
      background-color: $color-black;
      color: $color-white;
      @include breakpoint($medium-portrait-only) {
        background-color: $color-pink-accent;
        color: $color-white;
      }
    }
    @at-root .filters-open & {
      background-color: $color-pink-accent;
      color: $color-white;
      @include breakpoint($large-up) {
        background-color: $color-white;
        color: $color-black;
      }
      @include breakpoint($medium-portrait-only) {
        background-color: $color-pink-accent;
        color: $color-white;
      }
    }
    .state-init {
      display: none;
    }
    .state-toggled {
      display: block;
    }
    .filter-icon {
      display: none;
      @include breakpoint($large-up) {
        display: block;
      }
      @include breakpoint($medium-portrait-only) {
        display: none;
      }
      .line1 {
        margin-top: 0px;
        transform: rotate(45deg);
        width: 23px;
      }
      .line2 {
        transform: rotate(-45deg);
        width: 23px;
        margin-top: 0;
      }
    }
  }
  &.filters-open .mpp-sort {
    &__container {
      display: none;
      @include breakpoint($large-up) {
        display: block;
      }
      @include breakpoint($medium-portrait-only) {
        display: none;
      }
    }
  }
  &.filters-open &__filters {
    pointer-events: auto;
    @include breakpoint($medium-down) {
      position: absolute;
      opacity: 1;
      display: block;
      left: 50%;
      top: -40px;
      visibility: visible;
      z-index: 11;
      @include transform(translate(-50%, 0%));
      //clip-path: inset(0px 0px 0% 0px);
    }
    @include breakpoint($large-up) {
      height: auto;
      opacity: 1;
      width: 220px;
      @include transform(translate(0, 0));
      overflow: visible;
      transition-delay: 0.5s;
      visibility: visible;
      pointer-events: auto;
      padding: 0px 15px 30px 20px;
    }
    @include breakpoint($medium-portrait-only) {
      @include transform(translate(-50%, 0%));
      position: absolute;
      opacity: 1;
      display: block;
      left: 50%;
      top: -40px;
      visibility: visible;
      z-index: 11;
    }
    .mpp-filter-set-formatter {
      margin-top: 0px;
      @include breakpoint($large-up) {
        margin-top: 10px;
      }
    }
  }
  &.filters-open &__grid {
    @include breakpoint($large-up) {
      transition-delay: 0s;
      width: calc(100% - 160px);
    }
    @include breakpoint($medium-portrait-only) {
      width: 100%;
    }
  }
}

.mpp-container__breadcrumb {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  // align the filter test
  padding: 15px 15px 0px 15px;
  margin: 0 auto;
  @include breakpoint($large-up) {
    padding: 15px 15px 0px 15px;
  }
  .mpp-container__total-items {
    @include breadcrumb-font;
    margin-left: 15px;
    text-align: right;
    flex-shrink: 0;
  }
}

body.section-products .site-content,
body.using-mpp-layout .site-content {
  .field-elc-mpp-content,
  .field-mobile-template {
    // We're targetting the first template of an mpp node and assuming the
    // header text input is one paragraph. The extra div being added messed up
    // the current only-child logic. For mpp headers, this feels like a safe
    // bet
    > div:first-child {
      &.content-block--hero {
        @include contentBlockImageHeight;
        picture img {
          min-height: 100px;
        }
      }
      .content-block__line--header {
        p {
          margin: 0;
        }
      }
    }
  }
}

/*
 * Temporary to fix issue with AMP extension or whatever
 */
[data-ae_invis='true'] {
  display: none;
}
