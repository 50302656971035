.range-slider-carousel__style--shade_finder {
  .range-slider-carousel {
    &__wrapper {
      position: relative;
      @include breakpoint($medium-up) {
        max-width: 1440px;
      }
    }
    &__slider-ticks-menu {
      max-width: 1050px;
      margin: 5px auto 30px;
      @include breakpoint($medium-up) {
      }
    }
    &__slider {
      max-width: 1050px;
    }
    &__carousel {
      .slick-arrow {
        margin-top: -50px;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 2;
        @include breakpoint($medium-down) {
          display: none;
        }
      }
      .slick-prev {
        left: 8%;
      }
      .slick-next {
        right: 8%;
      }
    }
  }
  &.slide-active .range-slider-item__image:before {
    display: none;
  }
  .shade-finder-slide {
    margin: 0px 10px;
  }
  .range-slider-item__image {
    border: 1px solid #bf9b5f;
    overflow: visible;
    max-height: 435px;
    margin-bottom: 70px;
    @include breakpoint($medium-up) {
      margin-bottom: 50px;
    }
  }
  .slick-initialized .range-slider-item__image {
    height: auto;
  }
  .range-slider-item__toggle {
    transition: background 0.5s ease;
    position: absolute;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 80%;
    left: 0px;
    width: 100%;
    .shade-finder-slide--cta {
      margin-top: -5px;
      @include breakpoint($medium-up) {
        margin-top: 0px;
        font-size: 14px;
        opacity: 0;
      }
      a {
        @include link;
        @include link-caret;
        font-size: mobilePx(18px);
        @include breakpoint($medium-up) {
          font-size: 14px;
        }
      }
    }
  }
  .range-slider-item__image-smoosh {
    width: 70%;
    max-width: 150px;
  }
  .shade-name {
    @include font--proxima-nova-light;
    font-size: mobilePx(20px);
    margin: 8px 0 2px;
    text-align: center;
    @include breakpoint($medium-up) {
      @include font--proxima-nova;
      margin-top: 5px;
      font-size: 18px;
    }
  }
  .basic-carousel__slide:hover {
    @include breakpoint($medium-up) {
      .range-slider-item__image {
        border: 0px;
      }
      .range-slider-item__image-smoosh {
        max-width: 200px;
        width: 100%;
      }
      .range-slider-item__toggle {
        background: #fff;
        border: 1px solid #bf9b5f;
        top: 0px;
        left: 0px;
        width: 100%;
        height: calc(100% + 30px);
      }
      .shade-finder-slide--cta {
        opacity: 1;
        position: absolute;
        bottom: 15px;
      }
      .shade-name {
        @include font--proxima-nova-bold;
      }
    }
  }
}
