.social-login-section {
  text-align: center;
  .social-login {
    position: relative;
    margin: 20px 0;
    &__divider {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      display: inline-block;
      padding: 0 33px;
      margin: 15px auto 0;
      width: 100%;
      position: relative;
      &:before {
        content: '';
        background: $color-black;
        width: 100%;
        height: 1px;
        display: inline-block;
        position: absolute;
        top: 43%;
        left: 0;
      }
      .inner {
        display: inline-block;
        position: relative;
        padding: 5px 10px;
        background: $color-white;
      }
    }
    &__info {
      display: none;
    }
    &__email-opt-in {
      float: left;
      margin-top: 3px;
    }
    &__terms {
      text-align: center;
    }
    &__container {
      display: none;
    }
    &__title {
      display: none;
    }
    .facebook-logo {
      position: absolute;
      margin-top: 0.85em;
      left: 0;
      right: 0;
      cursor: pointer;
      img {
        height: 30px;
        width: 30px;
      }
      span {
        color: $color-white;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        padding-left: 10px;
      }
    }
    .facebook-button {
      background-color: $color-fb-blue;
      line-height: 3rem;
      height: 3.5rem;
      width: 100%;
      border: 0;
      color: $color-white;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}
