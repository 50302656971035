#loyalty__panel__next-tier {
  @include loyalty-panel;
  @include loyalty-clear;
  &.is-guest,
  &.loyalty__has-max-tier,
  &.loyalty__has-pending-points {
    display: none;
  }
  .loyalty__panel__next-tier__title {
    font-weight: bold;
    padding: 10px;
  }
  @include breakpoint($small-only) {
    .loyalty__panel__next-tier__title {
      border: 1px solid $color-light-gray;
      border-width: 1px 0;
    }
  }
  @include breakpoint($medium-up) {
    float: left;
    width: 30%;
    .loyalty__panel__next-tier__title {
      background-color: $color-light-gray;
    }
  }
}
