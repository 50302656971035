.sign-in-page {
  .new-account {
    .form-item {
      &.full-name {
        margin: 15px 0;
      }
    }
    &__social {
      @include breakpoint($landscape-up) {
        max-width: 55%;
        margin: 0 auto;
      }
    }
  }
}

.password-reset-page {
  padding: 70px 20px 0 20px;
  @include breakpoint($landscape-up) {
    padding: 50px 60px 100px;
  }
}

.past-purchases-page {
  &__content {
    .past-purchases {
      .product-item {
        float: none;
        display: inline-block;
        width: 49%;
        @include breakpoint($landscape-up) {
          width: 31%;
        }
      }
    }
  }
}

.address-book-page {
  &__overlay {
    .address-form {
      .postcode-needhelp,
      .ship_type_label,
      .phone_label_container {
        width: 100%;
        @include breakpoint($landscape-up) {
          width: 75%;
          height: 30px;
        }
      }
      .ship_type_label {
        @include breakpoint($landscape-up) {
          height: 40px;
          margin: 0 8px 10px;
        }
      }
      .form-item {
        label {
          line-height: 2.7;
        }
      }
      .default_ship_container {
        @include breakpoint($landscape-up) {
          margin-left: 10px;
          height: auto;
          float: left;
        }
      }
    }
  }
}

.lookup_results_container {
  .address_lookup_wrapper {
    border: 1px solid $color-gray;
    clear: both;
    padding: 10px;
    width: 100%;
    height: 200px;
    overflow: auto;
    margin-bottom: 15px;
    h3 {
      font-size: 40px;
      text-transform: uppercase;
      letter-spacing: -1px;
    }
    li {
      padding-bottom: 10px;
      .action-btn {
        background-color: $color-black;
        text-align: center;
        margin-top: 10px;
      }
    }
  }
}

.profile-page {
  .profile-info {
    &__item {
      margin-bottom: 0;
    }
  }
  .cpf-label {
    margin-top: 3%;
  }
  .social-signin {
    margin-top: 0;
    @include breakpoint($landscape-up) {
      margin-top: 10px;
    }
  }
}

.change-password {
  .profile-password-update {
    &__fieldset {
      .form-item input[type='password'] {
        box-sizing: border-box;
        padding: 10px 18px 0;
      }
    }
  }
}

.favorites-page {
  &__content {
    .favorites {
      .product-info {
        &__button {
          .button {
            line-height: 1.5;
          }
        }
      }
    }
  }
  .account-utilities {
    &__section {
      .sidebar-menu {
        .disabled {
          a {
            color: $color-gray;
            pointer-events: none;
          }
        }
      }
    }
  }
}

.past-purchases-page {
  &__content {
    .past-purchases {
      .product-item {
        &__add-to-cart {
          .button {
            line-height: 1.5;
            max-width: 98%;
          }
        }
      }
    }
  }
}

.account-section {
  .button {
    line-height: 1.5;
    min-width: 180px;
    height: 50px;
    margin-top: 10px;
  }
}

.account-utilities {
  &__header,
  &__customer-service {
    padding: 0;
  }
}

.order-timeline {
  margin-top: 20px;
  ul {
    display: flex;
    margin: auto;
  }
  &__step {
    text-align: center;
    position: relative;
    pointer-events: none;
    opacity: 0.3;
    flex: 1;
    + .order-timeline__step {
      margin-left: 2px;
      @include breakpoint($landscape-up) {
        margin-left: 25px;
      }
      &:before {
        content: '';
        border-top: 3px solid $color-pink-popup;
        width: calc(100% - 19px);
        position: absolute;
        top: 10px;
        transform: translate(calc(-100% - 11px));
        @include breakpoint($medium-up){
          width: calc(100% - 18px);
        }
        @include breakpoint($landscape-up) {
          width: 100%;
          transform: translate(calc(-100% - 13px));
        }
      }
    }
    &_active {
      opacity: 1;
      pointer-events: auto;
      .order-timeline__step {
        &-bullet {
          background-color: $color-pink-popup;
        }
      }
    }
    &-bullet {
      background-color: $color-white;
      border: 1px solid $color-pink-popup;
      border-radius: 100%;
      font-size: 14px;
      color: $color-white;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 10px;
      width: 25px;
      height: 25px;
    }
    &-title {
      font-size: 14px;
      max-width: none;
      margin: auto;
    }
    &-subtitle {
      font-family: inherit;
      font-size: 10px;
    }
  }
}

.order-details-page__content {
  .order-products {
    .cart-item {
      margin-bottom: 1em;
      padding: 1em 0;
    }
  }
}
