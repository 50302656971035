.product-brief {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: relative;
  text-align: center;
  .product__button--add-favorites {
    display: none;
  }
  &__image-wrapper {
    position: relative;
    margin-bottom: 3px;
  }
  .product__button--add-to-bag,
  .button--loading,
  .button--success {
    font-size: 12px;
    min-height: unset;
    min-width: unset;
    padding: 10px 20px;
    width: 100%;
    @include breakpoint($medium-up) {
      font-size: 14px;
    }
  }

  // default site ratio
  &:not([data-custom-ratio]) {
    .product__image-link {
      @include aspect-ratio(253, 383);
      img:first-child {
        position: absolute;
      }
    }
    &[data-grid-colspan='2'] {
      .product__image-link {
        @include aspect-ratio(535, 383);
      }
    }
  }

  // square ratio
  &[data-custom-ratio='square'] {
    .product__image-link {
      @include aspect-ratio(100, 100);
      img:first-child {
        position: absolute;
      }
    }
  }
  &__product-link {
    flex: 1 0 auto;
    text-decoration: none;
    &:hover {
      color: $color-black;
      text-decoration: none;
    }
    // hover states
    &:hover .product__image-link {
      @include breakpoint($landscape-up) {
        transition: all 0.3s ease;
        opacity: 1;
        img {
          opacity: 0;
        }
        // note that even if there is only one image. this will still display the
        // image
        img:last-child {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  .product__image-link {
    display: block;
    overflow: hidden;

    // with quickshop.
    @at-root .product-grid--quickshop & {
      @include breakpoint($large-up) {
        cursor: default;
      }
    }
    // on mobile we make sure hover image is display: none so it does not
    // lazyload.
    img {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      width: 100%;
      transition: opacity 0.15s ease-in-out 0s;
      cursor: pointer;
    }
    img:first-child {
      display: block;
      visibility: visible;
      position: relative;
      opacity: 1;
    }
    // On desktop we use visibility to lazyload occurs but with lesser
    // priority.
    @include breakpoint($landscape-up) {
      img {
        display: block;
        visibility: hidden;
      }
    }
  }
  &__description {
    font-size: 14px;
    margin-bottom: 3px;
    @include breakpoint($medium-up) {
      font-size: 16px;
    }
  }
  &__name {
    @include title-small;
    span {
      // Cut-off into ellipsis for more than 2 lines on mobile.
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      @include breakpoint($large-up) {
        display: block;
      }
    }
    span,
    span:hover {
      color: $color-black;
      text-decoration: none;
    }
  }
  &__price {
    @include title-small;
    .product-sku-price {
      &__value {
        @include title-small;
        display: inline-block;
      }
    }
    .price-installment,
    .product-sku-price__unit-price {
      display: block;
      font-size: 14px;
      color: $color-light-gray;
      margin: 8px 0;
    }
  }
  &__sku-shade-swatch {
    display: inline-block;
    margin-right: 0.5em;
    padding: 0.75em;
  }
  &__subline {
    padding-top: 3px;
    display: none;
  }
  &__short-desc {
    width: auto;
    max-width: 255px;
    margin: 8px auto;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    @include breakpoint($medium-up) {
      margin: 10px 0;
      font-size: 14px;
      white-space: nowrap;
    }
    span {
      // Cut-off into ellipsis for more than 2 lines on mobile.
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      @include breakpoint($medium-up) {
        overflow: visible;
        display: inline;
        -webkit-box-orient: unset;
        -webkit-line-clamp: unset;
      }
    }
  }
  &__cta {
    display: none;
  }
  &__cta--add-to-bag {
    margin: 5px auto 0;
    width: 100%;
    @include breakpoint($medium-up) {
      margin-top: 9px;
      width: auto;
    }
  }

  &__quickshop-launch {
    cursor: pointer;
    height: 0;
    opacity: 0;
    transition: opacity 1s ease;
    overflow: hidden;
    position: absolute;
    left: 0;
    z-index: 2;
    bottom: 15px;
    width: 100%;
    background: url(/media/images/products/mpp-quickview.gif) no-repeat;
    background-size: 135px auto;
    background-position: center;
    span {
      display: none;
    }
  }

  // hover states
  &:hover &__quickshop-launch {
    @include breakpoint($landscape-up) {
      opacity: 1;
      overflow: auto;
      height: 35px;
    }
    @include breakpoint($medium-landscape-only) {
      display: none;
    }
  }

  // hide badges on hover
  &:hover .product-flag__badge {
    @include breakpoint($large-up) {
      opacity: 0;
    }
  }

  // on hover. make sure flags don't block quickshop
  &:hover .product-flag__container {
    pointer-events: none;
  }

  // brief specific wrapper
  &__shade-picker {
    min-height: 55px;
    margin-bottom: 5px;
  }
  .product-shade-picker {
    $product-brief-hex-size: 10px;
    $product-brief-shade-size: 24px;
    $product-brief-highlight-size: 4px;
    opacity: 0;
    &__label {
      display: block;
    }
    &__shade_families {
      display: none;
    }
    &__shade {
      &__outer {
        width: $product-brief-shade-size;
        height: $product-brief-shade-size;
      }
      &__inner {
        width: 100%;
        height: 100%;
      }
      &.active .product-shade-picker__shade__outer {
        border: none;
      }
      &:hover .product-shade-picker__shade__outer {
        border: 1px solid $color-pink;
      }
      &.active .product-shade-picker__shade__color {
        align-items: center;
        display: flex;
        justify-content: center;
        &:before {
          background-color: $color-white;
          border-radius: 50%;
          content: '';
          padding: $product-brief-highlight-size;
          position: absolute;
        }
      }
    }
  }
  .product-shade-picker.has-missing-shades .product-shade-picker__shade-count {
    display: block;
    opacity: 0;
    font-size: 10px;
    transition: none;
    @include breakpoint($large-up) {
      font-size: 12px;
    }
  }
  .product-brief__rating-favorites-wrapper {
    .js-review-count-container {
      display: none;
    }
    .product-rating {
      @include breakpoint($medium-up) {
        padding-bottom: 11px;
      }
    }
  }
}

.product-grid__item.mg-item-reveal {
  .product-shade-picker {
    opacity: 1;
    padding: 0 10px;
    transform: translate(0, 0);
    transition: 0.5s all ease;
  }
  .product-shade-picker.has-missing-shades .product-shade-picker__shade-count {
    opacity: 1;
    transition: 1s all ease;
    transition-delay: 0.1s;
  }
}

.product-flag {
  $badge-width: 120px;
  $badge-width-mobile: 100px;
  &__badge {
    @include transform(translateX(-17%));
    @include aspect-ratio(1500, 500, 'span');
    background-size: contain;
    background-repeat: no-repeat;
    // shift badge so ribbon matches with ledge edge
    // this is done on badge because in future. other types of badges might
    // need different shfit numbers
    &__text {
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 24% !important; // this comes from aspect-ratio
      font-size: 8px;
      line-height: 1;
      @include font--proxima-nova-bold;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      @include breakpoint($medium-up) {
        font-size: 10px;
      }
    }
  }
  &__container {
    &--side {
      display: flex;
      flex-direction: column;
      position: absolute;
      z-index: 2;
      top: 8%;
      width: $badge-width-mobile;
      @include breakpoint($medium-up) {
        top: 14px;
        width: $badge-width;
      }
      img {
        opacity: 0;
        transition: all 0.5s ease;
        @at-root .product-grid__item.mg-item-reveal & {
          opacity: 1;
          transition-delay: 0.4s;
        }
      }
    }
    &--bottom {
      display: flex;
      flex-direction: column;
      position: absolute;
      z-index: 2;
      bottom: 8%;
      width: $badge-width-mobile;
      @include breakpoint($medium-up) {
        width: $badge-width;
      }
      opacity: 0;
      transition: all 0.5s ease;
      @at-root .product-grid__item.mg-item-reveal & {
        transition-delay: 0.4s;
        opacity: 1;
      }
    }
  }
}

.spp-cross-sells {
  .product-grid__item--slide {
    float: none;
    .product-brief {
      min-width: 100px;
      &[data-grid-colspan='2'] {
        min-width: 200px;
        .product-brief {
          &__image-wrapper {
            min-height: 125px;
          }
        }
      }
      &__name {
        min-height: 3.6em;
        & a {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      @include breakpoint($medium-up) {
        &__name {
          min-height: 2.5em;
          & a {
            -webkit-line-clamp: 2;
          }
        }
      }
    }
  }
}

@-moz-document url-prefix() {
  // Firefox
  .spp-cross-sells {
    .product-brief {
      &__name {
        @include add-ellipsis(3.6em);
        & a {
          max-height: 3.6em;
        }
        @include breakpoint($medium-up) {
          height: 2.5em;
          & a {
            max-height: 2.5em;
          }
          &::after {
            margin-top: -0.2em;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 0\0) and (min-resolution: 72dpi) {
  // IE9+
  .spp-cross-sells {
    .product-brief {
      &__name {
        @include add-ellipsis(3.6em);
        & a {
          max-height: 3.6em;
        }
        @include breakpoint($medium-up) {
          height: 2.5em;
          & a {
            max-height: 2.5em;
          }
        }
      }
    }
  }
}
