// Default Variables
// @todo update these old styles for new slick
// remove mnixins and references to toofaced-carousel-arrows and toofaced-carousel-arrows--small
// just include those styles here and override styles as needed...

$slick-dot-color: $color-gray;
$slick-dot-color-active: $color-black;
$slick-dot-size: 15px;
$slick-opacity-default: 1;
$slick-opacity-on-hover: 0.75;
$slick-opacity-not-active: 0.25;

.slick {
  &-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  &-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
    &:focus {
      outline: none;
    }
    &.dragging {
      cursor: pointer;
    }
  }
  &-track,
  &-list {
    @include transform(translate3d(0, 0, 0));
  }
  &-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    &:before,
    &:after {
      content: '';
      display: table;
    }
    &:after {
      clear: both;
    }
    .slick-loading & {
      visibility: hidden;
    }
  }
  &-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none;
    outline: none;
    [dir='rtl'] & {
      float: right;
    }
    &.slick-loading img {
      display: none;
    }
    &.dragging img {
      pointer-events: none;
    }
    .slick-initialized & {
      display: block;
    }
    .slick-loading & {
      visibility: hidden;
    }
    .slick-vertical & {
      display: block;
      height: auto;
      border: 1px solid transparent;
    }
  }

  &-prev,
  &-next {
    @include appearance(none);
    background-image: url(#{$base-theme-path}/img/icons/svg/arrow--right.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $color-carousel-arrow-bg-solid;
    position: absolute;
    z-index: 1;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    top: 50%;
    margin-top: -30px;
    padding: 0;
    border: none;
    outline: none;
    opacity: 1;
    color: $color-arrows-brown;
    width: 45px;
    height: 75px;
    transition: opacity 1s;
    text-align: center;
    display: block;
    border-radius: 0;
    &:hover {
      opacity: 1;
    }
    &:focus {
      outline: $outline;
    }
    &.slick-disabled {
      cursor: default;
      opacity: 0.4;
    }
  }
  &-prev {
    left: 20px;
    @include transform(rotate(180deg));
  }
  &-next {
    right: 20px;
    text-align: right;
  }
}

// Dots
// classes for cms overrides
// slick-dots--default
// slick-dots--gold
// slick-dots--default--pc
// slick-dots--gold--pc
.slick-dots {
  text-align: center;
  width: 100%;
  margin-top: 6px;
  @include breakpoint($large-up) {
    margin-top: 0;
  }
  li {
    position: relative;
    display: inline-block;
    height: $slick-dot-size;
    width: $slick-dot-size;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: transparent;
      display: block;
      height: $slick-dot-size;
      width: $slick-dot-size;
      outline: none;
      line-height: 0;
      font-size: 0;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      &:hover,
      &:focus {
        outline: none;
        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }
      &:before {
        background: $color-gray;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: $slick-dot-size;
        height: $slick-dot-size;
        font-size: $slick-dot-size;
        line-height: $slick-dot-size;
        text-align: center;
        color: $slick-dot-color;
        opacity: $slick-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        .slick-dots--gold & {
          background: $bg-gold-fade;
        }
        .slick-dots--default--pc & {
          @include breakpoint($medium-up) {
            background: $color-black;
          }
        }
        .slick-dots--gold--pc & {
          @include breakpoint($medium-up) {
            background: $bg-gold-fade;
          }
        }
      }
    }
    &.slick-active button:before {
      background: $color-black;
      color: $slick-dot-color-active;
      opacity: $slick-opacity-default;
      .slick-dots--gold & {
        background: $bg-gold-fade;
      }
      .slick-dots--default--pc & {
        @include breakpoint($medium-up) {
          background: $color-black;
        }
      }
      .slick-dots--gold--pc & {
        @include breakpoint($medium-up) {
          background: $bg-gold-fade;
        }
      }
    }
  }
}
