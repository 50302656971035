#loyalty__panel__points {
  @include loyalty-panel;
  @include breakpoint($medium-up) {
    // Making space for the Next Tier panel.
    float: left;
    width: 70%;
    &.loyalty__has-max-tier,
    &.loyalty__has-pending-points {
      float: none;
      width: auto;
    }
  }
  .loyalty__panel__points__available,
  .loyalty__panel__points__pending {
    float: left;
    width: 50%;
    .loyalty__panel__points__available-inner,
    .loyalty__panel__points__pending-inner {
      background: $color-light-gray;
      padding: 20px;
      text-align: center;
    }
    .loyalty__panel__points__available-title,
    .loyalty__panel__points__pending-title {
      display: block;
      margin-bottom: 10px;
    }
    .loyalty__panel__points__available-title,
    .loyalty__panel__points__available-amount {
      font-weight: bold;
    }
    .loyalty__panel__points__pending-title {
      font-style: italic;
    }
    .loyalty__panel__points__pending-annotation {
      text-align: right;
    }
  }
  .loyalty__panel__points__available {
    padding: 0 5px 0 0;
  }
  .loyalty__panel__points__pending {
    padding: 0 0 0 5px;
  }
}
