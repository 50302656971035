.sidecar-has-bg-image {
  background-size: 100% auto;
  background-repeat: repeat-y;
}

@mixin sidecar-max-width($padding: 0px) {
  &.sidecar-max-width {
    &--large {
      @include breakpoint(1920px + $padding * 2) {
        max-width: 1920px;
      }
    }
    &--medium {
      @include breakpoint(1280px + $padding * 2) {
        max-width: 1280px;
      }
    }
    &--small {
      @include breakpoint(960px + $padding * 2) {
        max-width: 960px;
      }
    }
  }
}

.sidecar-max-width {
  &--large {
    max-width: 1920px;
    margin: 0 auto;
  }
  &--medium {
    max-width: 1280px;
    margin: 0 auto;
  }
  &--small {
    max-width: 960px;
    margin: 0 auto;
  }
  &--product_grid {
    // mimic a product grid for width.
    // full row is 2 cols on mobile and 4 cols on desktop
    padding: 0 calc(#{$product-grid-padding-x} / 2);
    @include breakpoint($medium-up) {
      max-width: $product-grid-content-max-width;
      padding: 0 calc(#{$product-grid-padding-x} / 4);
      margin: 0 auto;
    }
  }
}

.sidecar-formatter {
  &[data-content-section-style='padding_around_content_equal'] .sidecar-content-wrapper {
    max-width: calc(100vw - (25px * 2));
    margin: 25px;
    padding: 25px;
    @include breakpoint($medium-up) {
      padding: 25px;
      margin: 100px;
      max-width: calc(100vw - (100px * 2));
    }
    // Past the max-width + padding * x threshold, dont base max-width on vw
    @include sidecar-max-width(100px);
  }
  &[data-content-section-style='padding_around_content_top_less'] .sidecar-content-wrapper {
    max-width: calc(100vw - (25px * 2));
    margin: 25px;
    padding: 25px;
    @include breakpoint($medium-up) {
      padding: 25px;
      margin: 30px 100px 100px 100px;
      max-width: calc(100vw - (100px * 2));
    }
    // Past the max-width + padding * x threshold, dont base max-width on vw
    @include sidecar-max-width(100px);
  }
  .sidecar-footer > div {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
