///
/// @file template_api/endeca/_search_results.scss
///
/// \brief Search Results
///
/// @todo test in header and redo styles
/// @setup new brand - these styles can be customized per design
///

.search-wrapper {
  &__loading {
    text-align: center;
    padding-bottom: 20px;
  }
  .summary-results {
    padding: 60px 0;
    margin-bottom: 6px;
    background-color: $color-pink-2;
    &__result {
      font-size: 20px;
      color: $color-dark-grey;
      margin-bottom: 6px;
    }
  }
  .summary {
    text-align: center;
    text-transform: uppercase;
    background-color: $color-white;
    border-bottom: 3px solid $color-border-gold-brown;
    padding-bottom: 4px;
    span {
      color: $color-pink-accent;
      .not-bold {
        font-weight: normal;
      }
    }
  }
  .results {
    &__products {
      .search-wrapper__sort {
        display: block;
        text-align: right;
      }
      .products {
        &__header {
          // temp hide sort
          display: none;
        }
      }
    }
    &__content {
      .results-summary {
        // temp hide sort
        display: none;
      }
    }
    .product-shade-picker {
      &__shade {
        pointer-events: auto;
      }
    }
  }
  .bestseller-results + .results {
    padding: 0 16.36%;
  }
  .search-results__no-results {
    .summary-search-form__form {
      input[type='text'] {
        padding: 0 16px;
        display: block;
        text-align: left;
        text-transform: uppercase;
        font-family: 'Proxima Nova';
        font-weight: 400;
        font-weight: 200;
        font-size: 16px;
        height: auto;
        border: none;
        -webkit-appearance: none;
        line-height: 38px;
        width: 100%;
        margin: 0 auto;
        @include breakpoint($medium-up) {
          width: 425px;
        }
      }
      input[type='submit'] {
        display: none;
      }
    }
    .summary-search-form__form--label {
      margin-bottom: 14px;
    }
    background: $color-pink-2;
    padding-top: 67px;
    .search-no-results__summary-search {
      font-size: 20px;
      .summary-search,
      .did-you-mean {
        display: inline-block;
        margin-bottom: 6px;
      }
    }
    .search-no-results__suggestion {
      width: 90%;
      margin-left: 6%;
      text-transform: none;
      @include breakpoint($medium-up) {
        margin: auto;
        padding-bottom: 10px;
        width: 473px;
      }
    }
    .summary-search-form {
      &__form--field {
        padding-bottom: 81px;
      }
      &__form--search {
        background: $color-white;
        margin: auto;
        width: 90%;
        height: 45px;
        @include breakpoint($medium-up) {
          width: 460px;
        }
        &-input {
          display: inline-block;
          width: 89%;
          padding: 4px;
          @include breakpoint($medium-up) {
            width: 91%;
          }
        }
        &-icon {
          display: inline-block;
          width: 32px;
        }
      }
    }
  }
  &__bestsellers {
    // This is the main wrapper for the "no results" content
    .bestseller-results {
      padding-top: 40px;
      .bestseller-results__heading {
        .goldbar-header:before {
          height: 9px;
        }
        .bestseller-results__heading-section {
          .bestseller-results__inner p {
            padding-top: 20px;
          }
        }
        .bestseller-results__inner p {
          text-align: center;
        }
      }
    }
  }
  .breadcrumb,
  .dimension {
    padding: 0.5em;
  }
  .breadcrumbs {
    position: relative;
    top: 0;
    left: 0;
    @include breakpoint($medium-up) {
      display: block;
    }
  }
}

// temporary 2 col layout for toofaced only
// @setup new brand may remove these classes from markup
// or adjust accordingly
// toofaced_base/template_api/endeca/esearch_results_pc_v1.mustache
.results__header--dimensions,
.results__header--breadcrumbs {
  .dimensions__results-wrapper,
  .breadcrumbs__results-wrapper {
    background-color: $color-pink-2;
    .dimensions__results,
    .breadcrumbs__results {
      top: -10px;
      position: relative;
      padding-left: 14px;
      padding-top: 20px;
      @include breakpoint($medium-up) {
        padding-top: 0;
      }
      .dimension,
      .breadcrumb {
        padding-top: 0;
      }
      .dimension__name,
      .breadcrumb__name {
        font-size: 14px;
        line-height: 25px;
        letter-spacing: 0.1em;
        font-weight: 700;
        margin-bottom: 6px;
        text-transform: uppercase;
      }
      a {
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.08em;
        text-decoration: none;
        color: $color-black;
        margin-top: 5px;
        margin-bottom: 5px;
        transition: color 0.3s ease;
        &:hover {
          text-decoration: none;
          color: $color-pink-accent;
        }
      }
    }
    .mpp-filter-set__footer {
      padding-top: 0;
      display: block;
      .breadcrumb__clear_results {
        padding-left: 20px;
      }
    }
    @include breakpoint($small-down) {
      display: none;
      width: 80%;
      margin: 0 auto;
      .dimensions__results {
        padding-left: 0;
      }
    }
  }
  .breadcrumbs__link--close .icon {
    fill: $color-pink-accent;
    float: right;
    height: 16px;
    margin: 0 5px;
    width: 16px;
  }
  .filter-toggle {
    display: flex;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 22px;
    font-weight: 700;
    letter-spacing: 0.15em;
    padding: 2em 15%;
    color: $color-dark-gray;
    text-decoration: none;
    @include breakpoint($medium-down) {
      cursor: pointer;
    }
    .filter-icon {
      width: 28px;
      height: 10px;
      position: relative;
      margin-left: 1em;
      color: $color-dark-gray;
      flex-wrap: nowrap;
      order: 1;
      display: block;
      .line1,
      .line2 {
        top: 10px;
        left: 0;
        transition: all 0.5s ease;
        position: absolute;
        border-bottom: 1px solid $color-dark-gray;
      }
      .line1 {
        width: 28px;
        transform: none;
        margin-top: 2px;
      }
      .line2 {
        width: 18px;
        transform: none;
        margin-top: -2px;
      }
      @include breakpoint($medium-up) {
        display: none;
      }
    }
    @include breakpoint($small-down) {
      width: 175px;
      padding-top: 22px;
      text-align: center;
      padding-left: 27px;
      .state-init {
        display: block;
        text-align: center;
        width: 100%;
      }
    }
  }
}

.results__header--breadcrumbs {
  .filter-toggle {
    padding-left: 3%;
    padding-right: 37px;
  }
}

.results__header--column {
  text-align: center;
  display: inline-block;
  width: 98%;
  @include breakpoint($medium-up) {
    width: 15%;
    float: left;
    height: 100%;
    padding-left: 0;
    position: relative;
    display: block;
    text-align: left;
  }
  .breadcrumbs__results-wrapper {
    @include breakpoint($medium-down) {
      display: block;
      margin-top: 58px;
    }
  }
}

.search-wrapper__results {
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  .products__content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    @include breakpoint($medium-up) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
  .search-wrapper__sort {
    position: absolute;
    top: 0;
    right: 55px;
    z-index: 9;
    @include breakpoint($medium-up) {
      float: unset;
      position: static;
    }
  }
  .mpp-sort__container {
    &:not(.open) {
      .mpp-sort__filter-link__text:after {
        transition: none;
      }
    }
    margin-top: 8px;
    width: auto;
    margin-left: 14px;
    @include breakpoint($large-up) {
      top: 14px;
    }
    @include breakpoint($medium-up) {
      margin-top: 0;
      margin-left: 85%;
    }
    @include breakpoint($medium-portrait-only) {
      top: unset;
      margin-left: 218px;
    }
    @include breakpoint($medium-landscape-only) {
      margin-left: 218px;
    }
  }
  .product__inventory-status {
    li {
      &.active {
        display: none;
      }
    }
  }
}

.results__products--column {
  margin: 0 1em;
  @include breakpoint($medium-up) {
    margin: 0 0 0 15%;
  }
  .results__header--dimensions {
    text-align: center;
    margin-bottom: 2em;
  }
}

.esearch-product {
  width: 100%;
}

.esearch-product__image {
  @include breakpoint($medium-up) {
    width: 100%;
  }
}

.esearch-product__content {
  text-align: center;
  .esearch-product__price {
    font-weight: 700;
  }
}

.esearch-product__headline a {
  color: $color-dark-gray;
  text-decoration: none;
}

// plain styles for responsive result on search page
.endeca-product-result {
  padding: 1rem 1.5rem;
}

.endeca-test-image {
  width: 100%;
  height: 375px;
  background-color: $color-gray;
  &--small {
    width: 50px;
    height: 62px;
  }
}

// dimensions
.results__header--dimensions {
  .results-header {
    cursor: pointer;
  }
}
