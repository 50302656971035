.mpp-sort {
  display: none;
  @include breakpoint($large-up) {
    display: block;
  }
  &__container {
    width: 100%;
    max-width: 80vw;
    z-index: 11;
    @include breakpoint($large-up) {
      position: relative;
      width: auto;
      left: 0;
    }
    li {
      cursor: pointer;
      padding: 0.5em 0;
    }
  }
  &__filter-link__text {
    display: inline-block;
    border-bottom: 1px solid transparent;
    margin-bottom: 5px;
    &:hover {
      color: $color-pink-accent;
    }
    &:after {
      transition: all 0.5s ease;
      background: $color-pink-1;
      height: 10px;
      display: block;
      content: ' ';
      margin-top: -10px;
      padding-right: 5px;
      opacity: 0;
      @at-root .mpp-sort__filter-link.active & {
        opacity: 1;
      }
    }
  }
  &__content {
    @include transform(translate(-50%, -60%));
    font-size: 14px;
    position: absolute;
    padding: 0 4em;
    background: $color-white;
    transition: all 0.5s ease;
    transition-property: opacity, transform, clip-path;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    transition-delay: 0s;
    max-width: 80vw;
    z-index: 10;
    white-space: nowrap;
    left: -50%;
    @include breakpoint($large-up) {
      @include transform(translate(0, 0));
      left: auto;
      visibility: visible;
      box-shadow: 1px 0 16px rgba(168, 38, 0, 0.16);
      border: 1px solid $color-filter-box-border;
      right: 0;
      top: 50px;
      max-height: 0;
    }
  }
  &__container.open &__content {
    @include transform(translate(-50%, 0));
    padding: 2em 4em;
    opacity: 1;
    max-height: 500px;
    left: 50%;
    position: absolute;
    display: block;
    top: auto;
    visibility: visible;
    z-index: 11;
    @include breakpoint($large-up) {
      @include transform(translate(0, 0));
      left: auto;
      top: auto;
    }
  }
  &__label {
    @include eyebrow-small;
    border: 0;
    background: transparent;
    position: relative;
    display: inline-flex;
    cursor: pointer;
    padding: 1em;
    padding-right: 0;
    margin-right: .5em;
    right: -55%;
    color: $color-dark-gray;
    @include breakpoint($large-up) {
      align-items: center;
      right: 0;
    }
    &-text {
      margin-right: 10px;
    }
    .icon--arrow--down {
      height: 10px;
      margin-right: 10px;
      transform-origin: 50% 50%;
      transition: all 0.5s ease;
      width: 10px;
    }
  }
  &__container.open &__label {
    .icon--arrow--down {
      transform: rotate(-180deg);
    }
  }
}

.filter-sort-toggle-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: center;
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 0;
  min-height: 20px;
  position: relative;
  .mpp-sort__container {
    @include breakpoint($medium-portrait-only) {
      right: 0;
      width: 60%;
    }
  }
  .mpp-sort__label {
    @include breakpoint($medium-portrait-only) {
      position: absolute;
      right: 0;
      transform: none;
    }
  }
}
