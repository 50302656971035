.site-footer {
  &__promo {
    margin-top: 60px;
  }
}

.checkout-panel {
  &--sign-in-main {
    .form-item {
      input {
        width: 100%;
        &[type='checkbox'] {
          width: auto;
        }
        &[type='text'].error,
        &[type='email'].error,
        &[type='password'].error {
          border-color: $color-pink-accent;
          color: $color-pink-accent;
        }
        &.adpl--processed {
          &:required {
            &.error:empty + label {
              &:before {
                color: $color-gold;
              }
            }
          }
        }
      }
    }
  }
  &--viewcart {
    .cart-items {
      &__item {
        display: flex;
      }
    }
  }
  &--confirmation {
    .confirmation-content {
      &__print-buttons {
        @include breakpoint($landscape-up) {
          width: 52%;
        }
        a {
          line-height: 1.5;
        }
      }
    }
    .payment_boleto_print {
      .button {
        height: 40px;
        margin-top: 15px;
        min-width: 200px;
      }
    }
  }
  .cpf-field {
    .postcode-zipfields {
      float: right;
      width: 50%;
      @include breakpoint($medium-up) {
        width: 78%;
      }
      @include breakpoint($landscape-up) {
        width: auto;
      }
    }
  }
  .calculate-fee {
    margin-top: 20px;
    float: left;
    @include breakpoint($medium-up) {
      clear: both;
    }
  }
}

.shipping-panel {
  .checkout-panel {
    &__content {
      .default-bill_to_shipping {
        margin-bottom: 10px;
      }
      .new-address {
        &.add {
          margin-top: 15px;
        }
      }
      .continue-button-container {
        .continue-button-wrapper {
          margin-top: 15px;
        }
      }
      .address-form {
        .form-item {
          margin-right: 15px;
          &.state-container {
            margin-bottom: 15px;
          }
        }
        .checkbox {
          width: 100%;
          margin-bottom: 15px;
          .label-content {
            display: inline-block;
          }
        }
        input {
          &[type='text'].checkedEmpty,
          &[type='tel'].checkedEmpty {
            border-color: $color-pink-accent;
          }
          &.adpl--processed {
            &:required {
              &.checkedEmpty + label {
                &:before {
                  color: $color-gold;
                }
              }
            }
          }
        }
        select {
          &.checkedEmpty {
            border-color: $color-pink-accent;
          }
        }
      }
      .select-address {
        .field-container {
          padding: 0 0 7px;
          .postal-code,
          .delivery-instructions {
            input {
              @include breakpoint($landscape-up) {
                width: 47%;
              }
            }
          }
        }
        .postal-code {
          label {
             clear: both;
          }
          input {
            margin-top: 4px;
          }
        }
      }
    }
  }
}

.checkout {
  padding: 0 16px;
  &__sidebar {
    .checkout-panel {
      padding: 15px;
      &--need-help {
        .contact_container {
          a {
            display: inline-grid;
            height: 80px;
            vertical-align: middle;
            width: 45%;
            @include breakpoint($landscape-up) {
              height: 60px;
            }
          }
          .mobile_number,
          .contact_us {
            padding: 28px 0;
            @include breakpoint($landscape-up) {
              padding: 19px 0;
            }
          }
        }
      }
    }
    .cart-items {
      &__item {
        &--group-desc-remove {
          width: 70%;
          @include breakpoint($landscape-up) {
            width: 100%;
          }
        }
        &--qty {
          margin-top: 0;
          width: 100%;
          margin-bottom: 0;
          &__label {
            margin-left: 5%;
          }
          &-toggle {
            margin-left: 0;
            width: 70%;
          }
          &__value {
            float: right;
          }
        }
        &--total {
          width: 100%;
          text-align: left;
          font-weight: 500;
          font-size: 15px;
          .price-value {
            float: right;
            font-weight: 700;
          }
        }
      }
    }
  }
}

.viewcart {
  .empty-cart {
    .checkout {
      &__sidebar {
        .checkout-panel {
          &--need-help {
            margin-top: 10px;
            background-image: none;
          }
        }
      }
      &__content {
        .continue-shopping {
          .button {
            font-size: 10px;
            @include breakpoint($landscape-up) {
              width: 55%;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .site-footer {
    display: none;
  }
}

.payment-edit-content {
  .payment-container {
    .address-container {
      .address-form {
        .form-item {
          @include breakpoint($landscape-up) {
            width: 47%;
            float: left;
            margin-right: 10px;
          }
          input {
            &[type='tel'],
            &[type='text'] {
              width: 100%;
            }
            &.adpl--processed {
              &:required {
                &.checkedEmpty + label {
                  &:before {
                    color: $color-gold;
                  }
                }
              }
            }
          }
          select {
            &.checkedEmpty {
              border-color: $color-pink-accent;
            }
          }
          span {
            &.label-content {
              display: none;
            }
          }
        }
        .postal-code {
          width: 100%;
          margin-bottom: 10px;
          input {
            @include breakpoint($landscape-up) {
              width: 47%;
            }
            &.adpl--processed {
              &:required {
                &.checkedEmpty + label {
                  &:before {
                    color: $color-gold;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.active-panel-registration {
  .checkout {
    .social-login-section {
      @include breakpoint($landscape-up) {
        max-width: 55%;
        margin: 0 auto;
      }
    }
  }
}

.active-panel-review {
  .adyen-payment {
    .chckt {
      &-pm {
        border: none;
        &__header {
          background-color: $color-pink-3;
        }
        &__details {
          background-color: $color-pink-3;
          padding: 0px 10px 10px;
          @include breakpoint($landscape-up) {
            padding: 0 22px 22px;
          }
          .chckt-form {
            @include breakpoint($landscape-up) {
              max-width: 100%;
            }
            &-label__text {
              color: $color-black;
              font-weight: 500;
            }
          }
        }
      }
      &-input-field {
        height: 40px;
        border: none;
        border-radius: 0;
        padding: 8px;
        &.js-chckt-holdername::placeholder {
          color: $color-light-gray;
          opacity: 1;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
            'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
          font-size: 15px;
          font-weight: 200;
          background-color: transparent;
        }
      }
      &--cvc {
        max-width: 100%;
      }
      &-form-label {
        &--exp-date {
          width: 55%;
        }
        &--cvc {
          width: 27%;
          @include breakpoint($landscape-up) {
            width: 22%;
          }
        }
      }
      &-select-box {
        border: none;
      }
      &-button-container {
        .chckt-button {
          width: inherit;
          float: right;
          padding: 13px 0;
          &--disabled {
            background: $color-gray;
          }
          @include breakpoint($landscape-up) {
            width: 350px;
          }
        }
      }
    }
  }
  .checkout {
    &__sidebar {
      .shipmethod {
        display: block;
      }
    }
  }
}
