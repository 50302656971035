.mantle-image-compare-box {
  position: relative;
  &__before {
    background: $color-white;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  &__after {
    // Start off with after not being shown.
    clip-path: inset(100% 0 0 0);
  }
}

.slide-comparison {
  max-width: 800px;
  padding: 100px;
  margin: 0 auto;
  &__divider {
    &:before {
      @include transform(translate(-50%, -50%));
      content: ' ';
      background: url('/media/export/cms/before-after/before-after-heart.png');
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 25px;
      height: 25px;
    }
    background: $color-before-after-gold;
    position: absolute;
    width: 3px;
    height: 100%;
    top: 0;
    left: 0;
  }
  &__divider-y {
    display: none;
    position: absolute;
    height: 5px;
    width: 100%;
    left: 0;
    top: 0;
  }
}
