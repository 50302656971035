.filterable-grid {
  $root: &;
  max-width: 1440px;
  margin: 0 auto;

  $grid-grid: '.filterable-grid__grid';
  @include mantle-grid-columns(3, $grid-grid);
  @include breakpoint($large-up) {
    @include mantle-grid-columns(7, $grid-grid);
    &[data-columns='6'] {
      @include mantle-grid-columns(6, $grid-grid);
    }
  }
  &__grid {
    @include display-grid;
    width: 100%;
    order: 5;
    grid-auto-flow: row dense;
    overflow: hidden;
    & > div {
      flex-wrap: wrap;
    }
  }

  // Grid placements. Some of this could conceivably be put into the CMS.
  &__grid-item {
    // enable data-grid-column, data-grid-row
    @include css-grid-data-attrs;
    &.big {
      @include breakpoint($large-up) {
        @include grid-row-span(2);
        @include grid-column-span(2);
      }
    }
  }

  // The promo landing pattern for mobile.
  .promo:not(.fillin) {
    @include breakpoint($medium-down) {
      @include grid-column-start(2);
      &[data-promo-index='1'] {
        @include grid-row-start(2);
      }
      &[data-promo-index='2'] {
        @include grid-element-start(4, 1);
      }
      &[data-promo-index='3'] {
        @include grid-element-start(4, 3);
      }
      &[data-promo-index='4'] {
        @include grid-row-start(6);
      }
      &[data-promo-index='5'] {
        @include grid-element-start(8, 1);
      }
      &[data-promo-index='6'] {
        @include grid-element-start(8, 3);
      }
      &[data-promo-index='7'] {
        @include grid-row-start(9);
      }
      &[data-promo-index='8'] {
        @include grid-row-start(11);
      }
    }
  }

  // When auto placed. The second Big item shows up on the right side.
  &__grid-item.big[data-big-index='2'] {
    @include breakpoint($large-up) {
      @include grid-element-start(1, 6);
    }
  }

  // promo fillins.
  &__grid-item.fillin[data-fillin-index='1'] {
    @include grid-element-start(2, 2);
    @include breakpoint($large-up) {
      @include grid-element-start(1, 3);
    }
  }
  &__grid-item.fillin[data-fillin-index='2'] {
    @include breakpoint($large-up) {
      @include grid-element-start(2, 4);
    }
  }
  &__grid-item.fillin[data-fillin-index='3'] {
    @include breakpoint($large-up) {
      @include grid-element-start(1, 5);
    }
  }

  // 6 column promo placements
  &[data-columns='6'] {
    @include breakpoint($large-up) {
      #{$root}__grid-item.big[data-big-index='2'] {
        @include grid-column-start(5);
      }
      #{$root}__grid-item.fillin[data-fillin-index='1'] {
        @include grid-element-start(1, 3);
      }
      #{$root}__grid-item.fillin[data-fillin-index='2'] {
        @include grid-element-start(2, 4);
      }
      #{$root}__grid-item.fillin[data-fillin-index='3'] {
        @include grid-element-start(2, 3);
      }
    }
  }
}
