// non carousel grid
$default-grid-grid: '.product-grid__content:not(.slick-initialized)';

@mixin mantle-grid-mixin($grid-item-selector, $padding-x, $height-ratio) {
  #{$grid-item-selector} {
    opacity: 0;
    position: relative;
    padding: 0 $padding-x; //
    padding-bottom: 40px;
    width: 100%;
    @include breakpoint($large-up) {
      padding-bottom: 35%; // 100px at max-width
    }
    @include breakpoint($medium-portrait-only) {
      padding-bottom: 40px;
    }
    @at-root html.no-js & {
      opacity: 1;
    }

    .product-grid__item__content {
      height: 100%;
    }
  }

  #{$grid-item-selector}.mg-item-reveal {
    opacity: 1;
    transform: none;
  }

  #{$grid-item-selector}[data-inline-content='1'] {
    .contextual-links-region {
      position: static; // omg seriously?
    }
    .product-grid__item__content {
      display: inline-flex;
      width: 100%;
      @include breakpoint($medium-portrait-only) {
        display: inline;
      }
      .product-grid__item__content__inline-content-wrapper {
        position: relative;
        width: 100%;
        .content-block--hero {
          position: relative;
        }
      }
    }
  }

  #{$grid-item-selector}[data-grid-colspan='2'] {
    $num-cols: 2;
    grid-column-end: span $num-cols;
    padding: 0 calc(#{$padding-x} / #{$num-cols});
    padding-bottom: 40px;
    @include breakpoint($large-up) {
      padding-bottom: calc(35% / #{$num-cols});
    }
    @include breakpoint($medium-portrait-only) {
      padding-bottom: 40px;
    }

    // on mobile. colspan2 will fill whole row. so i tneeds to take up its own
    // content
    @include breakpoint($medium-down) {
      .product-grid__item__content {
        .product-grid__item__content__inline-content-wrapper {
          position: static;
          .mpp-inline-tout {
            position: static;
          }
        }
      }
    }
    .product-brief__image-wrapper {
      /*
       * Note thta the 100% is the width of the image-wrapper and not grid
       * item.
       * We calculate what the image-wrapper width would be for a 1x col by
       * first remove the internal padding used by the colspan. then dividing
       * by colspan
       */
      $extra-padding: $num-cols - 1;
      .product__image-link {
        // reset the aspect-ratio sizer
        &:before {
          padding-top: calc(
            (100% - (#{$padding-x} * #{$extra-padding})) / #{$num-cols} * #{$height-ratio}
          );
        }
      }
    }
  }
  #{$grid-item-selector}[data-grid-colspan='3'] {
    $num-cols: 3;
    grid-column-end: span $num-cols;
    padding: 0 calc(#{$padding-x} / #{$num-cols});
    padding-bottom: 40px;
    @include breakpoint($large-up) {
      padding-bottom: calc(35% / #{$num-cols});
    }

    // on mobile. colspan2 will fill whole row. so i tneeds to take up its own
    // content
    @include breakpoint($medium-down) {
      .product-grid__item__content {
        .product-grid__item__content__inline-content-wrapper {
          position: static;
          .mpp-inline-tout {
            position: static;
          }
        }
      }
    }
    .product-brief__image-wrapper {
      /*
       * Note thta the 100% is the width of the image-wrapper and not grid
       * item.
       * We calculate what the image-wrapper width would be for a 1x col by
       * first remove the internal padding used by the colspan. then dividing
       * by colspan
       */
      $extra-padding: $num-cols - 1;
      .product__image-link {
        // reset the aspect-ratio sizer
        // TODO: these calcs are a bit off. I need to correctly determine the %
        // of thw image width that is taken up by column padding. This is
        // trickier because
        &:before {
          padding-top: calc(
            (100% - (#{$padding-x} * #{$extra-padding})) / #{$num-cols} * #{$height-ratio}
          );
        }
      }
    }
  }
  #{$grid-item-selector}[data-grid-colspan='4'] {
    grid-column-end: span 4;
    padding: 0 calc(#{$padding-x} / 4);
  }
  @include breakpoint($large-up) {
    #{$grid-item-selector}[data-grid-rowspan='2'] {
      grid-row-end: span 2;
    }
  }
}

// data-total-colspan is generated by js to notify css of how many total
// colspans are included in the grid. Due to how css grids work, if we want
// to center a partially filled grid. We need to specify it in the template
// because a %33 %33 %33 template will always expand the grid as if all 3
// columns were filled up.

/*
 * Util function to generate the percentage
 */
@mixin _mantle-grid-columns-n($columns) {
  $col-width: 100% / $columns;
  @include _mantle-grid-columns-n-col-width($columns, $col-width);
}

/*
 * Pass in the col-width to generate settings for partially filled grids.
 */
@mixin _mantle-grid-columns-n-col-width($columns, $col-width) {
  $col-template: $col-width;
  @for $i from 1 to $columns {
    $col-template: $col-template + ' ' + $col-width;
  }
  @include grid-columns(0, #{$col-template});
}

/*
 * grid-grid is the selector for a non carouseled grid.
 *
 * Set the grid cols and handels the total-colspan attributes
 */
@mixin mantle-grid-columns($columns, $grid-grid: $default-grid-grid) {
  #{$grid-grid},
  &[data-total-colspan] #{$grid-grid} {
    @include _mantle-grid-columns-n($columns);
  }
  @include mantle-grid-columns-total-colspan($columns, $grid-grid);
}

/*
 * Generate the data-total-colspan attrbitues. This is to handle partially
 * filled one row grids.
 */
@mixin mantle-grid-columns-total-colspan($columns: 8, $grid-grid: $default-grid-grid) {
  $col-width: 100% / $columns;
  @for $i from 1 through ($columns - 1) {
    &[data-total-colspan='#{$i}'] #{$grid-grid} {
      @include _mantle-grid-columns-n-col-width($i, $col-width);
    }
  }
}

@mixin mantle-grid-columns-4($grid-grid: $default-grid-grid) {
  @include mantle-grid-columns(4, $grid-grid);
}

@mixin mantle-grid-columns-3($grid-grid: $default-grid-grid) {
  @include mantle-grid-columns(3, $grid-grid);
}

@mixin mantle-grid-columns-2($grid-grid: $default-grid-grid) {
  @include mantle-grid-columns(2, $grid-grid);
}

@mixin mantle-grid-columns-1($grid-grid: $default-grid-grid) {
  @include mantle-grid-columns(1, $grid-grid);
}

@mixin flex-grid-columns($grid-item, $max-cols) {
  #{$grid-item} {
    width: calc(100% / #{$max-cols});
    @for $i from 1 through 4 {
      &[data-grid-colspan='#{$i}'] {
        @if $i < $max-cols {
          width: calc((100% / #{$max-cols}) * #{$i});
        } @else {
          width: 100%;
        }
      }
    }
  }

  // hide images so they dont trigger lazyload
  #{$grid-item}:nth-child(n + #{$max-cols + 1}) {
    .product__image-link {
      img.lazyload {
        display: none;
      }
    }
  }
}

/*
 * Util function to allow setting a data-grid-column/data-grid-row attr to do
 * placement. This makes it easier for JS to set placement in a cross browser
 * way.
 */
@mixin css-grid-data-attrs {
  @for $i from 1 through 10 {
    &[data-grid-column='#{$i}'] {
      @include grid-column-start($i);
    }
    &[data-grid-row='#{$i}'] {
      @include grid-row-start($i);
    }
  }
}
