.content-block {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: $max-width-large;
  margin: auto;
  background-color: $color-white;
  z-index: 0;
  overflow: hidden;
  picture img {
    width: 100%;
    z-index: 1;
  }
  &__header {
    .basic-carousel__header & {
      padding-bottom: 25px;
      @include breakpoint($medium-up) {
        padding-bottom: 40px;
      }
    }
  }
  &__inner {
    width: 100%;
    height: 100%;
    position: relative;
  }
  &__content {
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include breakpoint($medium-up) {
      display: flex;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  &.mobile-text-over-image .content-block__content {
    height: 100%;
  }
  &__line--content {
    a {
      text-decoration: none;
      // Swap anchor tag colors
      color: $color-link-hover;
      &:hover {
        color: $color-link;
      }
    }
  }
  // Mobile Horizontal Alignment
  .mobile-horizontal-align-left {
    align-items: flex-start;
    @include breakpoint($large-up) {
      align-items: inherit;
    }
  }
  .mobile-horizontal-align-center {
    align-items: center;
    @include breakpoint($large-up) {
      align-items: inherit;
    }
  }
  .mobile-horizontal-align-right {
    align-items: flex-end;
    @include breakpoint($large-up) {
      align-items: inherit;
    }
  }
  // Mobile Vertical Alignment
  .mobile-justify-start {
    justify-content: flex-start;
    @include breakpoint($large-up) {
      justify-content: inherit;
    }
  }
  .mobile-justify-center {
    justify-content: center;
    @include breakpoint($large-up) {
      align-items: inherit;
    }
  }
  .mobile-justify-end {
    justify-content: flex-end;
    @include breakpoint($large-up) {
      align-items: inherit;
    }
  }
  @include breakpoint($large-up) {
    // Horizontal Alignment
    .horizontal-align-left {
      align-items: flex-start;
    }
    .horizontal-align-center {
      align-items: center;
    }
    .horizontal-align-right {
      align-items: flex-end;
    }
    // Vertical Alignment
    .justify-start {
      justify-content: flex-start;
    }
    .justify-center {
      justify-content: center;
    }
    .justify-end {
      justify-content: flex-end;
    }
  }
  .max-width-30 {
    @include breakpoint($medium-up) {
      min-width: 400px;
    }
    @include breakpoint($landscape-up) {
      min-width: 450px;
    }
  }
  .max-width-40 {
    @include breakpoint($medium-up) {
      min-width: 425px;
    }
    @include breakpoint($landscape-up) {
      min-width: 500px;
    }
  }
  .mantle-custom-text {
    p:only-child {
      margin: 0;
    }
  }
  @at-root .content-block__content-buttons {
    .content-block .align-left & {
      align-items: flex-start;
    }
    .content-block .align-right & {
      align-items: flex-end;
    }
    .content-block .align-center & {
      align-items: center;
    }
     @include breakpoint($medium-down) {
      .content-block .mobile-align-left & {
        align-items: flex-start;
      }
      .content-block .mobile-align-right & {
        align-items: flex-end;
      }
      .content-block .mobile-align-center & {
        align-items: center;
      }
    }
  }
  .content-block__content-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    &.padding-top--bare {
      margin-top: 20px;
    }
  }
  .content-block__line--image + .content-block__content-buttons {
    margin-top: 10px;
  }
  .cta--bare {
    @include button;
  }
  .button,
  .cta--bare {
    margin-bottom: 10px;
  }
  .mantle-placement--active {
    width: 100%;
    @include breakpoint($small-down) {
      transform: none !important;
    }
    @include breakpoint($medium-up) {
      position: absolute;
      z-index: 2;
    }
  }

  // If text align is center. Make the text component boxes center aligned as
  // well.
  &__content-wrapper.align-center &__line .max-width {
    @include breakpoint($large-up) {
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__content-wrapper.mobile-align-center &__line .max-width-mobile {
    @include breakpoint($medium-down) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.content-block--hero {
  .content-block__content-wrapper .content-block__content-text {
    max-width: 100%;
  }
  .content-block__picture {
    position: relative;
  }
  &.mobile-text-inside-image {
    @include breakpoint($small-down) {
      .content-block__picture {
        position: relative;
        .content-block__content-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        .content-block__content {
          height: 100%;
          &.padding-vertical--bare {
            padding-bottom: 0;
          }
        }
        .content-block__content-text.padding-vertical--bare {
          padding-bottom: 0;
        }
        .content-block__line--content {
          margin: 5px auto;
        }
      }
      .content-block__content-wrapper {
        .content-block__content.padding-vertical--bare {
          padding-top: 0;
        }
        .content-block__content-text.padding-vertical--bare {
          padding-top: 0;
        }
        .content-block__line--content {
          margin: 5px auto;
        }
      }
    }
  }
  &.mobile-text-over-image {
    @include breakpoint($medium-down) {
      @include contentBlockImageHeight;
    }
  }
  @include breakpoint($medium-up) {
    @include contentBlockImageHeight;
  }
  .content-block__picture--minheight img {
    @include breakpoint($large-up) {
      min-height: 400px;
    }
  }
}

.content-block--text {
  @include contentBlockTextHeight;
  // Content Block Modifier Templates
  max-width: 100%;
  &-aligned {
    .content-block__content-text {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: min-content;
      grid-template-areas:
        "Eyebrow"
        "Header"
        "Content--1"
        "Content--2"
        "Content--3";
      @include breakpoint($medium-up) {
        grid-column-gap: 10px;
        grid-template-columns: fit-content(100%) 1fr;
        grid-template-areas:
          "Eyebrow Eyebrow"
          "Header Content--1"
          "Content--2 Content--2"
          "Content--3 Content--3";
      }
      .content-block__line--eyebrow {
        grid-area: Eyebrow;
      }
      .content-block__line--header {
        grid-area: Header;
      }
      .content-block__line--content {
        grid-area: Content--1;
      }
      .content-block__line--content.content-block__line--content--2 {
        grid-area: Content--2;
      }
      .content-block__line--content.content-block__line--content--3 {
        grid-area: Content--3;
      }
    }
  }
  .content-block__content-text {
    width: 100%;
  }
  .content-block__background-text:not(.content-block__background-text-main) {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint($small-down) {
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .content-block__background-text-main {
    transform: translateX(-50%);
    margin-left: 50%;
    width: 100%;
  }
}

.style--bare {
  .content-block__line--header & {
    @include header-medium;
  }
}

.content-formatter {
  &__wrapper {
    max-width: $max-width-large;
    margin: auto;
    text-align: center;
  }
  &__eyebrow {
    @include goldbar-header;
  }
  &__header {
    @include header-medium;
    @include breakpoint($medium-up) {
      line-height: 1;
      margin-top: -4px;
    }
  }
  &__rendered {
    margin-bottom: 20px;
    @include breakpoint($large-up) {
      margin-bottom: 30px;
    }
  }
}

.padding-vertical--bare > .padding-vertical--bare {
  padding-top: 10px;
  padding-bottom: 10px;
}
