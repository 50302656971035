///
/// collapsible-menu
/// A content block that becomes click-expandable with a title
///
// .collapsible-menu {
// }
.collapsible-menu-trigger {
  background: url(#{$base-theme-path}svg-icons/src/plus.svg) no-repeat;
  position: relative;
  cursor: pointer;
  &:after {
    position: absolute;
    right: 0;
    font-size: 1em;
    top: 0.5em;
  }
  .collapsible-menu__parent--expanded & {
    background: url(#{$base-theme-path}svg-icons/src/minus.svg) no-repeat;
  }
}

.collapsible-sub-menu {
  display: none;
  .collapsible-menu__parent--expanded & {
    display: block;
  }
}
