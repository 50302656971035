.form-formatter {
  &__items {
    padding: 50px 0px;
    @include breakpoint($medium-up) {
      padding: 100px 0px;
    }
  }
  &__item {
    padding: 0px;
    margin-bottom: 50px;
    @include breakpoint($medium-up) {
      padding: 0px;
      margin-bottom: 100px;
    }
  }
  &[data-form-style='show_single'] {
    .form-formatter__items {
      padding: 0px;
    }
    .form-formatter__item {
      margin: 0px;
      padding: 0px;
      height: 0px;
      overflow: hidden;
      opacity: 0;
      transition: opacity 0.5s ease;
      &.active {
        padding: 40px 0 50px;
        height: auto;
        opacity: 1;
        @include breakpoint($medium-up) {
          padding: 100px 0 200px;
        }
      }
    }
  }
}
