.product-full {
  margin: 0 auto;
  // just covering the top in mobile. the media container will handle the
  // gradient the rest of the way
  background: linear-gradient($color-pink-2, $color-pink-2 200px, $color-white 200px);
  @include breakpoint($large-up) {
    background: none;
    @if $cr24 {
      margin: auto;
    } @else {
      margin: 0 auto 100px;
    }
  }
  .breadcrumbs {
    position: static;
    display: flex;
    margin: 0 auto;
    @if $cr24 {
      padding: 10px $pdp-breadcrumb-lr-padding;
      @include breakpoint($large-up) {
        padding: 20px;
        justify-content: flex-start;
        margin-bottom: 60px;
        background: $color-pink-2;
      }
      // CR24: hide the product-level breadcrumb on pdp
      .breadcrumbs__level--4 {
        display: none;
      }
    } @else {
      padding: 20px 8vw 25px;
      @include breakpoint($medium-up) {
        padding: 20px;
        justify-content: flex-start;
        margin-bottom: 60px;
        background: $color-pink-2;
      }
    }
    &__content {
      max-width: 1280px;
      @include breakpoint($large-up) {
        width: 100%;
      }
    }
  }
  .product-shade-picker__shades {
    display: flex;
    flex-wrap: wrap;
    float: none;
    margin-#{$ldirection}: 8px;
    @include breakpoint($large-up) {
      margin-#{$ldirection}: auto;
    }
    .product-shade-picker__shade-item {
      margin-#{$rdirection}: 4px;
      @include breakpoint($large-up) {
        margin-#{$rdirection}: 5px;
      }
    }
  }
}

.product-full__container {
  position: relative;
  max-width: $max-width;
  padding: 0 0 25px;
  margin: 0 auto;
  @include breakpoint($large-up) {
    display: flex;
    flex-direction: row;
    padding: 0 20px 25px 20px;
  }
}

.product-full__header {
  display: none;
  @include breakpoint($large-up) {
    display: block;
  }
}

.product-full__mobile-header {
  padding: 0 $pdp-breadcrumb-lr-padding;
  @include breakpoint($large-up) {
    display: none;
  }
}

.product-full__flag {
  display: none;
}

.product-full__media {
  position: relative;
  min-height: 200px; // fallback if images fails
  margin-bottom: 10px;
  background: linear-gradient($color-pink-2, $color-white);
  @include breakpoint($large-up) {
    background: none;
    margin-bottom: 20px;
  }
  @include breakpoint($large-up) {
    width: 62.35063%;
  }
}

.product-full__content {
  position: relative;
  @include breakpoint($large-up) {
    flex: 1;
    padding: 0;
    margin-left: 70px;
  }
  &-wrapper {
    display: flex;
    flex-direction: column;
  }
}

.product-full__product-info {
  padding: 0 10px;
  @include breakpoint($large-up) {
    padding: 0;
  }
}

.product-full__content-wrapper {
  text-align: center;
  @include breakpoint($large-up) {
    text-align: left;
    max-width: 410px;
  }
}

.product-full__name,
.product-full__subline {
  @include title-large;
  order: 0;
  text-align: center;
  margin-bottom: 3px;
  @include breakpoint($large-up) {
    margin-bottom: 10px;
    text-align: left;
  }
}

.product-full__short-description {
  @include body-medium;
  order: 2;
  text-align: center;
  @include breakpoint($large-up) {
    text-align: left;
    margin-bottom: 5px;
  }
}

.product-full__top-size {
  order: 4;
  @include body-medium;
  text-align: center;
  @include breakpoint($large-up) {
    text-align: left;
  }
}

.product-full__rating {
  @if $cr24 {
    order: 6;
    padding: 3px 0;
  } @else {
    order: 15; // after shadepicker-description
    margin: mobilePx(35px) mobilePx(45px);
  }
  @include breakpoint($large-up) {
    order: 6;
    margin: 0;
  }
  .review-snippet {
    display: flex;
    align-items: center;
    a {
      @include body-small;
      text-decoration: none;
      &:hover {
        color: $color-pink-accent;
      }
    }
    &__stars {
      @if $cr24 {
        @include ltr-rule(order, 1, 3);
        @include ltr-rule(margin-left, 0);
      } @else {
        @include ltr-rule(order, 3, 1);
        @include ltr-rule(margin-left, 10px);
        @include breakpoint($large-up) {
          @include ltr-rule(order, 1, 3);
          @include ltr-rule(margin-left, 0);
        }
      }
      .pr-rating-stars {
        display: inline-flex;
      }
    }
    // The flipping on and off of review count vs write review is done in
    // .product-rating section.
    &__count,
    &__write-review {
      @include ltr-rule(order, 1, 3);
      @include breakpoint($large-up) {
        @include ltr-rule(order, 3, 1);
        @include ltr-rule(margin-left, 12px);
      }
    }
    &__write-review {
      display: none;
      a {
        text-decoration: underline;
      }
    }
  }
  .product-rating {
    display: flex;
    align-items: center;
    transition: opacity 0.5s ease;
    justify-content: center;
    opacity: 0;
    @include breakpoint($large-up) {
      padding: 0;
      justify-content: flex-start;
      @if $cr24 {
        padding-top: 10px;
      } @else {
        padding-top: 20px;
      }
    }

    // Flip show write review link if we have no reviews
    &:not([data-pr-has-reviews='1']) {
      .review-snippet__count {
        display: none;
      }
      .review-snippet__write-review {
        display: block;
      }
    }
    &.pr-loaded {
      opacity: 1;
    }
  }
}

.product-full__price-wrapper {
  @include pie-clearfix;
  order: 8;
  margin-top: 10px;
  @include breakpoint($large-up) {
    @if $cr24 {
      margin: 8px 0 10px;
    } @else {
      margin: 20px 0 10px;
    }
    border: 0;
  }
}

.product-full__price {
  display: inline;
  .product-sku-price {
    display: inline-block;
  }
}

.product-full__inventory-status {
  text-align: center;
  text-transform: uppercase;
  line-height: 1em;
  white-space: nowrap;
  @include breakpoint($large-up) {
    text-align: left;
    display: inline;
  }
}

.product-full__installment--price {
  .afterpay-paragraph {
    @include breakpoint($medium-down) {
      margin: 0;
    }
  }
}

.product-full__price {
  .product-sku-price {
    &__value {
      font-size: 18px;
      @include breakpoint($large-up) {
        font-size: 30px;
      }
    }
  }
}

.product-sku-price {
  &__value {
    @include body-large;
    display: inline-block;
    font-weight: 700;
  }
  &__unit-price,
  .price-installment {
    display: block;
    font-size: 14px;
    color: $color-light-gray;
    margin-top: 5px;
  }
}

.product__price--non-sale {
  display: inline-block;
  position: relative;
  &:after {
    content: '';
    /* required property */
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 2px solid $color-pink-accent;
    /* adjust as necessary, depending on line thickness */
    /* or use calc() if you don't need to support IE8: */
    height: calc(50% + 2px);
    /* 1px = half the line thickness */
    width: 100%;
  }
}

.product__price--sale {
  display: inline-block;
  color: $color-pink-accent;
}

/*
 * Container for waitlist / add to bag cta.
 */
.product-full__cta-button-container {
  flex: 1;
  .button {
    cursor: pointer;
    width: 100%;
    min-width: auto;
    height: $add-to-bag-button-height--desktop;
    line-height: $add-to-bag-button-height--desktop;
  }
}

.product-full__cta {
  text-align: center;
  @include breakpoint($large-up) {
    width: 100%;
    text-align: left;
  }
  &.add-to-cart-row {
    display: flex;
    width: 100%;
    @include breakpoint($large-up) {
      display: block;
      position: relative;
      margin-top: 10px;
      z-index: inherit;
      padding: 0;
    }
    .product-qty-select {
      margin-top: 0;
      margin-right: 10px;
      width: 100px;
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
      // No need for qty when waitlist is active or add to bag disabled
      .waitlist-active &,
      .add-to-bag-cta-disabled & {
        opacity: 0;
        width: 0;
        overflow: hidden;
        margin-right: 0;
      }
      select {
        // replicating mobile button;
        padding-left: 30px;
        height: mobilePx(110px);
        line-height: mobilePx(110px);
        border: 1px solid $color-black;
      }
      @include breakpoint($large-up) {
        display: none;
      }
    }
  }
  &.add-favorites {
    @include ltr-rule(padding-right, 10px);
    text-align: $ldirection;
    @include breakpoint($large-up) {
      text-align: center;
      padding-right: 0;
      width: 100px; // Match quantity dropdown.
    }
    button {
      padding: 0;
      line-height: 1;
    }
  }
}

.product-full__image {
  width: 100%;
  margin: 0 auto;
  @include breakpoint($large-up) {
    max-width: 100%;
  }
}

// *
// * vertical accordion
// *
.product-full__accordion__container {
  @if $cr24 {
    margin: 0 auto 60px;
  } @else {
    margin: 0 0 15px;
  }
}

.product-full__accordion {
  border-top: 1px solid $color-lighter-gray;
  @include breakpoint($large-up) {
    border-top: 1px solid $color-light-gray;
  }
}

.product-full__accordion:last-child {
  border-bottom: 1px solid $color-lighter-gray;
  @include breakpoint($large-up) {
    border-bottom: 1px solid $color-light-gray;
  }
}

.product-full__accordion__title,
.product-full__mobile-panel__title {
  @include accordion-title;
  max-width: $product-full-accordion-max-width;
  margin: 0 auto;
}

.product-full__accordion__panel {
  display: none;
  margin: 0 0 20px 0;
  @include breakpoint($large-up) {
    margin: 0 0 10px 0;
  }
  &.init--open {
    display: block;
  }
}
// end accordion

.product-full__mobile-panel__title {
  &::after {
    display: none;
    content: '';
  }
}

.product-full__mobile-panel__panel {
  margin: 0 0 10px 0;
}

.product-full__replenishment {
  padding-bottom: 10px;
  @include breakpoint($large-up) {
    padding-bottom: 0;
  }
}

.product-full__offer__container {
  order: 8;
  margin-bottom: 8px;
}

.product-full__shadepicker {
  order: 10;
  border-top: 1px solid $color-lighter-gray;
  padding-top: 15px;
  margin-top: 10px;
  @include breakpoint($large-up) {
    margin: 15px 0 15px;
    padding-top: 0;
    border-top: 0;
  }
}

.product-full__offer {
  @include clearfix;
}

.product-full__offer__url {
  @include clearfix;
  display: block;
  text-decoration: none;
}

.product-full__offer__image {
  display: block;
  float: left;
  width: 36px;
  margin: 0 6px 4px 0;
  @include breakpoint($large-up) {
    width: 38px;
  }
}

.product-full__offer__content {
  display: inline;
  font-family: $font--proxima-nova;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  p {
    margin: 0;
  }
  @include breakpoint($large-up) {
    font-size: 14px;
    line-height: 16px;
  }
}

.product-full__shadname {
  font-size: 13px;
  text-transform: none;
  margin-left: 10px;
}

.product-full__flex {
  order: 12;
  display: flex;
  width: 100%;
  margin: 10px 0 0;
  @include breakpoint($large-up) {
    margin: 10px 0 5px;
  }
  > * {
    display: inline-block;
    flex: 1;
  }
  > .quantity-select {
    display: none;
    @include breakpoint($large-up) {
      display: inline-block;
      width: 100px;
      flex: 0 0 100px;
    }
    .product-qty-select {
      width: auto;
      margin-right: 10px;
      select {
        width: 100%;
        height: mobilePx(110px);
        border-color: $color-black;
      }
    }
  }
}

.product-full-footer-sticky-anchor {
  order: 16;
}

.product-full__footer {
  @if $cr24 {
    &__sticky {
      background: $color-pink-3;
      bottom: 0;
      left: 0;
      position: fixed;
    }
  } @else {
    background: $color-pink-3;
    bottom: 0;
    left: 0;
    position: fixed;
  }
  order: 16;
  z-index: 101;
  width: 100%;
  padding: 15px;
  @include breakpoint($large-up) {
    background: transparent;
    position: relative;
    margin-top: 0;
    border: 0;
    padding: 0;
  }
}

.product-full__sub-description {
  order: 12;
  @include breakpoint($large-up) {
    margin: 10px 0 5px 0;
    order: 13;
  }

  // This is for when we have no size/shade dropdowns
  &--no-shade-size {
    margin: 0;
    order: 8;
  }
  p:last-child {
    margin: 0;
  }
}

.product-full__shade-line {
  @include font--proxima-nova;
  align-items: center;
  display: none;
  font-size: 15px;
  padding: 0 0 10px;
  // *
  // * CR24 only show the shadeline if the parent has the
  // * .product-full__footer__sticky class and data-shaded='1'
  // *
  @if $cr24 {
    .product-full__footer__sticky & {
      [data-shaded='1'] & {
        display: flex;
      }
    }
  } @else {
    [data-shaded='1'] & {
      display: flex;
      @include breakpoint($large-up) {
        display: none;
      }
    }
  }
  &__label {
    font-weight: bold;
    margin-right: 2px;
  }
  &__circle {
    background-size: cover;
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
  }
}

.product-full__footer-extras {
  order: 18;
  // *
  // * CR24 don't show socials or favorite btn
  // *
  @if $cr24 {
    display: none;
  }
}

.sku-select-shades,
.sku-select-sizes {
  select {
    width: 100%;
  }
  @include breakpoint($medium-down) {
    text-align: left;
    padding: 0 15px;
    select {
      width: auto;
      border: 0;
      font-size: mobilePx(25px);
      height: 25px;
      line-height: 25px;
    }
  }
}

.sku-select-sizes {
  @include breakpoint($medium-down) {
    margin-bottom: 15px;
  }
}

.product-full__shade-description {
  @include body-small;
  order: 14;
  color: $color-black;
  text-align: left;
  margin-left: 15px;
  margin-top: 3px;
  padding-bottom: mobilePx(25px);
  @include breakpoint($large-up) {
    margin-left: 0px;
    margin-bottom: 20px;
    margin-top: 7px;
    padding-bottom: 0;
  }
}

.product__button--add-favorites {
  border: 0;
  background: transparent;
  text-decoration: none;
  font-size: 30px;
  color: $color-black;
  &:hover {
    text-decoration: none;
    .icon use {
      stroke: $color-pink-accent;
    }
  }
  &.favorites-loading {
    animation: favorites-loading 1.5s infinite ease-in-out;
  }
  &.favorite-added {
    color: $color-pink-accent;
    .icon {
      fill: $color-pink-accent;
      use {
        stroke: $color-pink-accent;
      }
    }
  }
  .icon {
    width: 34px;
    height: 32px;
    fill: transparent;
    use {
      stroke: $color-black;
      stroke-width: 5%;
    }
  }
  span {
    display: none !important;
  }
}

@keyframes favorites-loading {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateX(0deg);
  }
  50% {
    transform: perspective(120px) rotateY(-180.1deg) rotateX(0deg);
  }
  100% {
    transform: perspective(120px) rotateY(-360deg) rotateX(0deg);
  }
}

.tooltipster-toofaced {
  background: transparent;
  color: $color-pink-accent;
  max-width: 300px;
  a {
    color: $color-pink-accent;
  }
  .tooltipster-content {
    font-size: 14px;
    line-height: normal;
    overflow: hidden;
    padding: 0;
  }
  .anon-message {
    display: block;
    text-transform: none;
    body.signed-in & {
      display: none;
    }
  }
}

.spp-content {
  .basic-formatter {
    .content-block__eyebrow {
      .body-large {
        margin-top: 20px;
      }
    }
  }
}

// social
.product-full__social {
  display: none;
  @include breakpoint($large-up) {
    // CR24: keep socials hidden
    @if $cr24 {
      display: none;
    } @else {
      display: block;
    }
    margin-left: 25%;
    margin-top: 20px;
  }
  .product-full__footer-extras & {
    display: block;
    @include breakpoint($large-up) {
      display: none;
    }
  }
  .product-social {
    display: flex;
    justify-content: center;
    align-items: center;
    &__link {
      margin: 0 8px;
      color: $color-black;
      text-decoration: none;
    }
    &__item--line {
      img {
        width: 20px;
      }
    }
  }
  .icon--facebook,
  .icon--pinterest {
    font-size: 18px;
  }
}

.product-full__social-fav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  @include breakpoint($large-up) {
    margin-top: 15px;
  }
}

.product-full__size-cta,
.product-full__others-cta {
  vertical-align: middle;
}

.product-full__cta-button {
  flex: 1;
}

.product-full {
  &__installment {
    &--price {
      .afterpay-link {
        width: 15px;
        height: 15px;
        background: $color-cornflower-blue;
        border-radius: 50%;
        text-align: center;
        padding-right: 1px;
        font-family: $font--helvetica;
        font-size: 9.69px;
        font-weight: 400;
        text-transform: lowercase;
        text-decoration: none !important;
        color: $color-white !important;
        line-height: 1.56;
        vertical-align: text-bottom;
      }
      .afterpay-logo {
        width: 80px;
      }
      .afterpay-text1 {
        font-size: 14px;
      }
      .afterpay-text2 {
        @include body-small;
      }
    }
  }
}

@if $cr24 {
  .product-full.add-to-bag-cta-disabled {
    .js-product-full__footer {
      display: none;
    }
  }
}
